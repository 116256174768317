<template>
    <Modal :inner-class="'lpAlertBox'" v-if="alert" :close="close">
        <div class="text-center px-6">
            <p>{{ alert.message }}</p>
        </div>
    </Modal>
</template>
<script lang="ts">
import Modal from '@/components/ui/molecules/Modal';
import { Alert } from '@/store';
export default {
    mounted(){
        //Listen to anywhere click not containing class lpAlertBox
        document.addEventListener('click', this.onOutsideClick); 
    },
    beforeUnmount(){
        document.removeEventListener('click', this.onOutsideClick);
    },
    components:{
        Modal
    },
    watch: {
        alert(){
            if(this.alert?.autoClose){
                setTimeout(this.close, this.alert.autoClose);
            }
        }
    },
    computed:{
        alert(): Alert|null{
            return this.$store.state.alerts[0] || null;
        }
    },
    methods:{
        close(){
            this.$store.commit('removeAlert');
        },
        onOutsideClick(e: MouseEvent){
            if(!this.alert) return;
            console.log("Timestamp e, alert", e.timeStamp, this.alert.timeStamp);
            if(e.timeStamp < this.alert.timeStamp) {
                return;
            }
            if(!e.target.closest('.lpAlertBox')){
                this.close();
            }
        }
    }
}
</script>