<template>
  <div class="flex flex-col gap-[8px]" :class="variant == 'dark' ? 'lp-dark': 'lp-light'">
    <Text v-if="title" :variant="'label'"  size="14" thickness="medium" :color="variant == 'dark' ? 'white' : 'thickGray'">{{ title }} <span class="text-crewfareBlue" v-if="required"> *</span></Text>
    <div class="flex gap-4 items-center">
      <vue-tel-input 
        @blur="emitBlur"
        v-if="isPhoneInput" 
        :class="inputClasses"
        v-model="phoneNumber"
        @on-input="handlePhoneInput"
        :inputOptions="{
          placeholder: placeholder,
        }"
        :dropdownOptions="{
          showDialCodeInSelection: true,
          showFlags: true
        }"
        :validCharactersOnly="true"
        @validate="handlePhoneValidation"
        
      >
      </vue-tel-input>
      <GMapAutocomplete
        @blur="emitBlur"
        v-else-if="isLocationSearch"
        class=""
        :class="inputClasses"
        :placeholder="placeholder"
        @place_changed="(place: any) => locationFunction?.(place)"
      />
      <div v-else class="flex flex-1 relative">
          <input
          ref="input"
          class="flex-1"
          @blur="emitBlur"
          :type="(showPassword || !isPasswordInput) ? 'text': 'password'"
          :placeholder="placeholder"
          :class="inputClasses"
          :disabled="isDisabled || isLocked"
          v-model="inputValue"
          @input="handleTextInput"
          :readonly="readonly"
          :name="name"
        />
        <div @click="showPassword = !showPassword">
          <font-awesome-icon
            v-if="isPasswordInput" class="absolute cursor-pointer right-5 text-[#A5A5A5] top-4"
                          :icon="['fas', showPassword ? 'eye' : 'eye-slash']"
          />
        </div>
      </div>
      <div
        v-if="iconUrl"
        class="w-6 h-6 bg-contain bg-no-repeat cursor-pointer"
        :style="{ backgroundImage: `url(${iconUrl})` }"
        @click="() => onClickIcon?.()"
      ></div>
      
    </div>
    <p v-if="isError" class="text-crewfareRed text-xs">{{ errorMessage }}</p>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import Text from '../Text';

export default {
  components:{
    Text,
  },
  props: {
    isTitleText: {
      type: Boolean,
      default: false,
    },
    title: String,
    inputFilter: {
      type: String as PropType<'location' | 'phone' | 'text'>,
      default: 'text',
    },
    isBoldTitle: Boolean,
    placeholder: String,
    isError: Boolean,
    isLocked: Boolean,
    isDisabled: Boolean,
    errorMessage: String,
    iconUrl: String,
    onClickIcon: Function,
    modelValue: String,
    locationFunction: Function,
    readonly: Boolean,
    variant: {
      type: String as PropType<'dark' | 'light'>,
      default: 'dark',
    },
    required: Boolean,
    isPasswordInput: {
      type: Boolean,
      default: false
    },
    border: {
      type: String as PropType<'solid' | 'no-border'>,
      default: 'no-border'
    },
    name: String,
  },
  data() {
    return {
      inputValue: this.modelValue,
      blurred: false,
      phoneNumber: '',
      classes: {
        default: 'border-neutralGrey',
        error: '  !border-crewfareRed !border-[1px]',
        locked: '',
        disabled:
          ' text-crewfareGrey cursor-not-allowed border-crewfareGreyLighter hover:border-crewfareGreyLighter',
      } as { [key: string]: string },
      phone: {
        countryCode: '',
        number: '',
        isValid: false,
        countryCallingCode: '',
      },
      showPassword: false
    };
  },
  computed: {
    isLocationSearch(){
      return this.inputFilter === 'location';
    },
    isPhoneInput(){
      return this.inputFilter === 'phone';
    },
    actionableClass() {
      switch (true) {
        case this.isLocked:
        return this.classes.locked;
        case this.isError:
        return this.classes.error;
        case this.isDisabled:
        return this.classes.disabled;
        default:
        return this.classes.default;
      }
    },
    inputClasses(){
      const classMap = {
        'dark': 'box-border w-full !rounded-[8px] text-sm hover:!border-crewfarePurple appearance-none focus:outline-none font-normal text-white focus:ring-0 focus:outline-none w-full bg-crewfareGrey ',
        'light': 'box-border w-full !rounded-[8px] text-sm hover:!border-crewfarePurple appearance-none focus:outline-none font-normal text-black focus:ring-0 focus:outline-none w-full bg-lpWhite ',
        'no-border': 'border-transparent',
        'solid': 'border-solid',
      }
      return {
        [classMap[this.variant]]: true,
        [this.actionableClass]: true,
        'light': this.variant === 'light',
        'dark': this.variant === 'dark',
        'lp-tel-input py-1 px-1 border-none': this.isPhoneInput,
        'px-4 py-3': !this.isPhoneInput,
        [classMap[this.border]]: true,
        'border-[0.25px] focus:border-[1px] hover:border-[1px]': true
      };
    },
    labelClasses(){
      const classMap = {
        'dark': 'text-white text-base',
        'light': 'text-black text-base',
      }

      return classMap[this.variant] + `${this.isBoldTitle ? ' font-bold' : ''} ${!this.isBoldTitle ? ' font-medium' : ''}` + this.actionableClass;
    }
  },
  mounted(){
    this.phoneNumber = this.modelValue || '';
  },
  methods: {
    focus(){
      (this.$refs.input as HTMLInputElement).focus();
    },
    handleTextInput(event: any){
      let value = (event.target as HTMLInputElement).value
      if(this.isTitleText){
        value = value.replace(/[^a-zA-Z0-9 .,]/g, "")
        value = value.replace(/(,+)/g,',')
        value = value.replace(/\.+/g,'.')
        value = value.replace(/,+[ ]*[,\.]+/g,',')  
        value = value.replace(/\.+[ ]*[,\.]+/g,'.')  
      }
      this.inputValue = value;
      this.$emit('update:modelValue', value)
    },
    emitBlur(){
      this.blurred = true;
      this.$emit('blur');
    },
    handlePhoneInput(number: string, phoneObject: any){
      this.handlePhoneValidation(phoneObject);
    },
    handlePhoneValidation(phoneObject: any){
      this.phone.isValid = phoneObject.valid;
      this.phone.number = phoneObject.nationalNumber;
      this.phone.countryCode = phoneObject.countryCode;
      this.phone.countryCallingCode = phoneObject.countryCallingCode
      this.$emit('update:modelValue', phoneObject.number); 
      this.$emit('change', this.phone);
    }
  },
  emits: ['update:modelValue', 'change', 'blur'],
};
</script>
