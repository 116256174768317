<template>
    <div 
        :class="{
            'sm:w-[560px] w-full': isMedium, 
            'w-fit': isContained,
            'items-center': isCentered,
            'items-start': isStart,
            'items-end': isEnd,
            'justify-center': justifyCenter,
            'justify-start': justifyStart,
            'justify-end': justifyEnd
            }" 
        class="bg-white min-h-40 rounded-3xl p-3 md:p-12 flex flex-col"
    >
        <slot></slot>
    </div>
</template>
<script lang="ts">
import { PropType } from 'vue';

export default {
    computed: {
        isMedium() {
            return this.size === 'medium';
        },
        isContained() {
            return this.size === 'contained';
        },
        isFull(){
            return this.size === 'full';
        },
        isCentered(){
            return this.hPosition === 'center';
        },
        isStart(){
            return this.hPosition === 'start';
        },
        isEnd(){
            return this.hPosition === 'end';
        },
        justifyCenter(){
            return this.vPosition === 'center';
        },
        justifyStart(){
            return this.vPosition === 'start';
        },
        justifyEnd(){
            return this.vPosition === 'end';
        }
    },
    props: {
        size: {
            type: String as PropType<'full' | 'medium' | 'contained'>,
            default: 'full'
        },
        'hPosition': {
            type: String as PropType<'center' | 'start' | 'end'>,
            default: 'center'
        },
        'vPosition': {
            type: String as PropType<'center' | 'start' | 'end'>,
            default: 'center'
        }
    }
}
</script>