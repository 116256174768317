<template>
    <AccountBg>
        <SetNewPassword v-if="!reseted" :invalid="invalid" :reset="doReset" :loading="loading" :reset-err="error" />
        <ResetConfirmation v-else />
    </AccountBg>
</template>
<script lang="ts">
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import AccountBg from '../../organism/Account/AccountBg/AccountBg.vue';
import SetNewPassword from '../../organism/Account/SetNewPassword/SetNewPassword.vue';
import { auth } from '@/utils/firebase';
import { sendEmail } from '@/utils/emails';
import { invalid } from 'moment';
import { passwordUpdatedEmailContent, passwordUpdatedSubject } from '@/emails/password-updated';
import ResetConfirmation from '../../organism/Account/ResetConfirmation/ResetConfirmation.vue';

export default {
    data() {
        return {
            loading: false,
            error: "",
            reseted: false,
            email: "",
            invalid: false
        };
    },
    components: {
        AccountBg,
        SetNewPassword,
        ResetConfirmation
    },
    created() {
        const code = this.$route.query.oobCode;
        verifyPasswordResetCode(auth, code)
        .then((email) => {
            this.email = email;
            this.invalid = false;
        })
        .catch(() => {
            this.error = "Reset link is invalid or expired";
            this.invalid = true;
        });
    },
    methods: {
        doReset(password: string) {
            this.loading = true;
            this.error = "";
            const code = this.$route.query.oobCode;
            confirmPasswordReset(auth, code, password)
                .then(() => {
                this.loading = false;
                this.reseted = true;
                sendEmail({
                    to: this.email,
                    template: "emails.new-default",
                    subject: passwordUpdatedSubject,
                    content: passwordUpdatedEmailContent,
                });
            })
            .catch((e) => {
            this.loading = false;
            this.error =
                "Something went wrong updating your password, try again later";
            });
    },
    }
}
</script>