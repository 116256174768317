<template>
  <Modal :inner-class="'min-w-[800px] max-h-[90vh] overflow-y-scroll'" :close="closeModal">
    <div class="text-2xl font-bold mb-4">Last Changes</div>
    <div class="grid grid-cols-[1fr,1fr] gap-y-4 items-center w-full font-bold text-crewfareGreen">
      <div>Action</div>
      <div>Last Updated</div>
    </div>
    <Divider class="mb-4 mt-1" />
    <div class="grid grid-cols-[1fr,1fr] gap-y-6 items-center w-full" v-for="change in template.changes">
      <div>{{ `Last modified by ${change.name}` }}</div>
      <div>{{ formatDate(change.date) }}</div>
    </div>
  </Modal>
</template>

<script>
import Divider from '@/components/ui/atoms/Divider.vue';
import Modal from '@/components/ui/molecules/Modal';
import { getDateObjFromFirebaseDate } from '@/utils/dateUtils';

export default {
  components: {
    Modal,
    Divider,
  },
  props: {
    closeModal: Function,
    template: Object,
  },
  methods: {
    formatDate(date) {
      return getDateObjFromFirebaseDate(date).toLocaleString();
    },
  },
};
</script>
