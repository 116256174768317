import store, { Alert } from '@/store';

export const lpAlert = (message: string, autoClose: number = 0) => {
  store.commit('addAlert', { message, autoClose, timeStamp: window.performance.now() } as Alert);
};

export const lpPrompt = async (title: string, subtitle: string) => {
  return new Promise<string>(resolve => {
    store.commit('addPrompt', { title, subtitle, callback: resolve });
  });
};
