<template>
    <AccountBg>
        <LoginCard :defaultEmail="email" :isNew="isNew" :login="handleSubmit" :loading="loading" :login-error="message" />
    </AccountBg>
</template>
<script lang="ts">
import { signInWithEmailAndPassword } from 'firebase/auth';
import AccountBg from '../../organism/Account/AccountBg/AccountBg.vue';
import LoginCard from '../../organism/Account/LoginCard/LoginCard.vue';
import { auth } from '@/utils/firebase';
import { accountsApi } from '@/utils/apis/accountsApi';

export default {
    data() {
        return {
            loading: false,
            message: '',
            accountID: '',
            email: '',
            isNew: false
        }
    },
    components: {
        AccountBg,
        LoginCard
    },
    mounted(){
        this.accountID = this.$route.query.accountId;
        if(this.accountID){
            this.getAccount();
        }
    },
    methods: {
        async getAccount() {
            const account = await accountsApi.get(this.accountID)
            if(!account.error) {
                const accountData = account.data;
                this.email = account?.data?.email.toLowerCase().trim();
                if (!accountData.owner) {
                    this.isNew = true;
                }
            }

        },
        async handleSubmit(email: string, password: string) {
            if(this.isNew) {
                this.doCreateAccount(email, password);
            } else {
                this.doLogin(email, password);
            }
        },
        async doCreateAccount(email: string, password: string) {
            this.loading = true;
            this.message = "";
            const created = await accountsApi.createPassword({
                email: email.toLowerCase().trim(),
                password: password,
                accountId: this.accountID,
            })
            console.log("Created", created);
            if(created.error) {
                this.loading = false;
                this.message = created.message;
            } else {
                this.doLogin(email, password);
            }
        },
        doLogin(email: string, password: string) {
            this.loading = true;
            this.message = "";
            signInWithEmailAndPassword(
                auth,
                email.toLowerCase(),
                password
            )
                .then(async (result) => {
                })
                .catch((e) => {
                this.loading = false;
                this.message = "Invalid email or password.";
                });
        },
    }
}
</script>