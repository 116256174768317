<template>
    <svg :width="width" :height="height" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.16699 6.08301L14.0948 15.1325C15.1416 15.8653 15.6651 16.2317 16.2344 16.3736C16.7373 16.4989 17.2633 16.4989 17.7662 16.3736C18.3356 16.2317 18.859 15.8653 19.9059 15.1325L32.8337 6.08301M8.76699 26.6663H25.2337C27.8939 26.6663 29.224 26.6663 30.2401 26.1486C31.1339 25.6932 31.8605 24.9666 32.3159 24.0728C32.8337 23.0567 32.8337 21.7266 32.8337 19.0663V8.93301C32.8337 6.27276 32.8337 4.94263 32.3159 3.92655C31.8605 3.03278 31.1339 2.30613 30.2401 1.85073C29.224 1.33301 27.8939 1.33301 25.2337 1.33301H8.76699C6.10674 1.33301 4.77662 1.33301 3.76054 1.85073C2.86677 2.30613 2.14011 3.03278 1.68471 3.92655C1.16699 4.94263 1.16699 6.27276 1.16699 8.93301V19.0663C1.16699 21.7266 1.16699 23.0567 1.68471 24.0728C2.14011 24.9666 2.86677 25.6932 3.76054 26.1486C4.77662 26.6663 6.10674 26.6663 8.76699 26.6663Z" 
            :stroke="color" 
            stroke-width="1.5" 
            stroke-linecap="round" 
            stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: '#3E24F6'
        },
        width: {
            type: Number,
            default: 34
        }
    },
    computed: {
        height() {
            return this.width * 0.8235;
        }
    }
}
</script>