<template>
    <Modal :close="close">
        <div class="flex w-full justify-center gap-4">
            <div>
                <p class="text-xl font-semibold my-4">Add New Chain </p>
                <form @submit.prevent="true">
                <Input placeholder="Chain Name" class="w-full" v-model="chainName" />
                <Button :passed-function="addChain" class="my-4">
                    <Spinner v-if="loading"/>
                    <span v-else>Add</span>
                </Button>
            </form>
            </div>
        </div>
    </Modal>
</template>
<script lang="ts">
import Button from '@/components/ui/atoms/Button';
import Input from '@/components/ui/atoms/Input';
import Modal from '@/components/ui/molecules/Modal';
import Spinner from '@/components/ui/atoms/Spinner.vue';
import { hotelsApi } from '@/utils/apis/hotelsApi';

export default {
    components:{
        Modal,
        Button,
        Input,
        Spinner
    },
    data(){
        return {
            loading: false,
            chainName: ''
        }
    },
    methods:{
        close(){
            this.$emit('close');
        },
        async addChain(){
            this.loading = true;
            const chainRes = await hotelsApi.createChain(this.chainName.trim());
            this.loading = false;
            if(chainRes.error){
                alert(chainRes.message || 'Failed to add chain');
            }
            else{
                this.$emit('close', this.chainName);
            }
            
        }
    }
}
</script>