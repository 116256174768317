<template>
  <FwbTimelineItem style="margin-bottom: 12px; font-family: arial">
    <FwbTimelinePoint> </FwbTimelinePoint>
    <FwbTimelineContent>
      <FwbTimelineTime>
        <span class="scale-75" v-html="statusPill"></span>
      </FwbTimelineTime>
      <FwbTimelineTitle>
        <div
          class="flex gap-2 flex-col items-start print:text-black !pb-0 !mb-0"
          v-html="item.content"
        ></div>
      </FwbTimelineTitle>
      <FwbTimelineBody>
        <div class="flex flex-col gap-2">
          <p v-if="item.reasonDecline">
            Reason:
            <span v-html="item.reasonDecline"></span>
          </p>
          <p v-if="item.changes">
            Changes:
            {{ changesParsed }}
          </p>
          <small class="text-sm">by {{ abbreviatedName }} - {{ type }} on {{ formatedCreatedAt }}</small>
        </div>
      </FwbTimelineBody>
    </FwbTimelineContent>
  </FwbTimelineItem>
</template>

<script>
import {
  FwbTimelineItem,
  FwbTimelinePoint,
  FwbTimelineTime,
  FwbTimelineContent,
  FwbTimelineTitle,
  FwbTimelineBody,
} from "flowbite-vue";
import { RFPStatusPill } from "@/utils/rfp.jsx";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import { isSourceUser } from "@crewfare/server-shared/accounts/userTypesUtil";
import { titleCase } from "@crewfare/utils";

export default {
  props: ["item"],
  components: {
    FwbTimelineItem,
    FwbTimelinePoint,
    FwbTimelineTime,
    FwbTimelineContent,
    FwbTimelineTitle,
    FwbTimelineBody,
  },
  data() {
    return {
      termsParse: {
        terms: "Terms",
        attrition: "Attrition",
        commission: "Commission(%)",
        iata: "IATA #",
        rebate: "Rebate",
        groupDateHonored: "Group Rate Honored",
        compRooms: "Comp Rooms",
        staffRooms: "Staff Rooms",
        suiteUpgrades: "Suite Upgrades",
        staffDiscount: "Staff Discount",
        rewardPoints: "Reward Points",
        resortFee: "Resort Fee",
        reservationSubmission: "Reservation Submission",
        billing: "Billing",
        incidentals: "Incidentals",
        incidentalsOther: "Incidentals Other",
        billingsOther: "Billings Other",
        resortFeeAmount: "Resort Fee Amount",
        staffDiscountAmount: "Staff Discount Amount",
        rebateType: "RebateType",
        groupRateTypePre: "Group Rate Type Pre",
        groupRateTypePost: "Group Rate Type Post",
        commissionAmount: "Commission Amount",
        roomList: "Room List",
        wifi: "Wi-fi",
        breakfast: "Breakfast",
        breakfastOther: "Breakfast Other",
        parking: "Parking",
        parkingOther: "Parking Other",
        groupDateHonoredPostDays: "Group Date Honored Post Days",
        groupDateHonoredPreDays: "Group Date Honored Pre Days",
        otherFees: "Other Fees",
        otherFeesAmount: "Other Fess Amount",
      },
    };
  },
  computed: {
    changesParsed() {
      const changes = this.item.changes.filter((change) => !!change);
      return changes.map((change) => this.termsParse[change]).join(", ");
    },
    statusPill() {
      if (!this.item.status) return "";
      return RFPStatusPill(this.item.status);
    },
    formatedCreatedAt() {
      const createdDate = this.item.updated_at || this.item.created_at || this.item.date;
      if (!createdDate) return "";
      const created_at = getMomentFromFirebaseDate(createdDate);
      return created_at.format("ddd MMM DD, YYYY [at] hh:mm A");
    },
    abbreviatedName(){
      const accountName = this.item.account.name;
      if (!accountName) return "";
      if(this.isCrewfareSystem) return "Automation";
      const nameChunks = accountName.split(" ");
      if (nameChunks.length === 1) return nameChunks[0];
      const firstChar = accountName.charAt(0).toUpperCase();
      const rest = nameChunks.slice(1).join(" ");
      return `${firstChar}. ${rest}`;
    },
    isCrewfareSystem(){
      if (!this.item.account) return false;
      return this.item.account.type.toLowerCase() === "crewfare system";
    },
    type() {
      if (!this.item.account) return;
      const accountType = this.item.account.type.toLowerCase();
      if(this.isCrewfareSystem) return "Crewfare System";
      if (isSourceUser(this.item.account)){
        return `Crewfare ${titleCase(accountType)}`;
      }
      else {
        return `Hotel ${titleCase(accountType)}`;
      }
    },
  },
};
</script>
