<template>
  <FwbDropdown placement="bottom">
    <template #trigger>
      <div
        class="flex cursor-pointer dark:text-white text-white items-center gap-4 w-[140px] justify-between"
      >
        <span
          class="whitespace-nowrap hidden md:block text-ellipsis overflow-hidden max-w-[100px]"
        >
          {{ account.name }}
        </span>
        <span class="material-symbols-rounded"> expand_more </span>
      </div>
    </template>
    <FwbListGroup class="-ml-[140px] w-[274px] dark:bg-gray-700 bg-gray-700">
      <FwbListGroupItem class="!px-0 !py-0">
        <div class="flex gap-2 flex-col px-4 py-2 dark:text-white text-white">
          <div class="flex flex-col">
            <span class="text-xl">{{ account.name }}</span>
            <span
              class="text-sm text-gray-400"
            >
              Role:{{" "}}
              <span :class="isNSO ? 'uppercase' : 'capitalize'">
                {{ account.type }}
              </span>
            </span>
          </div>
          <span class="max-w-[240px] h-[20px] text-ellipsis overflow-hidden">
            {{ account.email }}
          </span>
        </div>
      </FwbListGroupItem>
      <FwbListGroupItem class="!px-0 !py-0">
        <router-link
          :to="{ name: 'myAccount' }"
          class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
        >
          My Account
        </router-link>
      </FwbListGroupItem>
      <FwbListGroupItem class="!px-0 !py-0">
        <button
          @click="doLogout"
          class="text-sky-400 hover:text-sky-100 flex items-center gap-2 px-4 py-2 w-full"
        >
          Logout
        </button>
      </FwbListGroupItem>
    </FwbListGroup>
  </FwbDropdown>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth } from "@/utils/firebase";
import router from "@/router";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from "flowbite-vue";

export default {
  components: {
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem,
  },
  computed: {
    isNSO() {
      return this.account.type.toLowerCase() === "nso";
    },
    initials() {
      if (!this.account.name) return "";
      const nameSplit = this.account.name.split(" ");
      const firstLetter = nameSplit[0];
      let lastLetter = "";
      if (nameSplit.length > 1) {
        const lastName = nameSplit.reverse()[0];
        lastLetter = lastName[0] || "";
      }
      return `${firstLetter[0]}${lastLetter}`;
    },
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    doLogout() {
      this.$store.commit("setAccount", null);
      signOut(auth).then(() => {
        this.$router.push("/")
      });
    },
  },
};
</script>
