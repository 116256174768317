<template>
    <Card size="medium" h-position="center" vPosition="start">
        <form @submit.prevent="null" class="w-full flex flex-col gap-y-10">
        <Text align='center' size="24" class="sm:text-40" thickness="bold" variant="h1">
            Create an Account
        </Text>
        <div class="flex flex-col gap-y-6">
            <div class="flex justify-between align-center gap-x-4">
                <div class="flex flex-1 flex-col gap-y-2">
                    <Text color="thickGray" size="14" thickness="medium" variant='label'>First Name</Text>
                    <Input ref="firstNameInput" v-model="firstName" :error-message="firstNameErr" :is-error="!!firstNameErr" placeholder="Type here" variant="light" border='solid' @blur="validateFirstName" />
                </div>
                <div class="flex flex-1 flex-col gap-y-2">
                    <Text color="thickGray" size="14" thickness="medium" variant='label'>Last Name</Text>
                    <Input ref="lastNameInput" v-model="lastName" :error-message="lastNameErr" :is-error="!!lastNameErr" placeholder="Type here" variant="light" border='solid' @blur="validateLastName" />
                </div>
            </div>
            <div class="flex flex-col gap-y-2">
                <Text color="thickGray" size="14" thickness="medium" variant='label'>Email Address</Text>
                <Input name="email" ref="emailInput" v-model="email" :error-message="emailErr" :is-error="!!emailErr" placeholder="e.g. john@company.com" variant="light" border='solid' @blur="validateEmail" />
            </div>
            <div class="flex flex-col gap-y-2">
                <Text color="thickGray" size="14" thickness="medium" variant='label'>Password</Text>
                <Input name="password" ref="passwordInput" v-model="password" :error-message="passErr" :is-error="!!passErr" placeholder="•••••••••••••••••••" isPasswordInput variant="light" border='solid' @blur="validatePassword" />
            </div>
        </div>
        <div class="w-full flex items-center flex-col gap-y-6">
            <Button @click="handleSubmit" size='full' theme="light">
                <span v-if="loading"><Spinner size="18px" /></span>
                <span v-else>Continue</span>
            </Button>
            <Text size="14" align='center' thickness="normal" variant="span" color='gray'>
                Already have an account? 
                <a href="/"><Text variant="span" color="blue" size="14"thickness='semiBold' > Login</Text></a>
            </Text>
            <Text align='center' size="14" thickness="normal" variant="span" color='gray'>
                Do you want to sign up a hotel?
                <a href="/signup/hotel"><Text variant="span" color="blue" size="14"thickness='semiBold' > Add a Hotel</Text></a>
            </Text>
            <Text v-if="!loading && signupError" size="14" thickness="normal" variant="span" color="red">{{ signupError }}</Text>
        </div>
    </form>
    </Card>
</template>
<script lang="ts">
import Button from '@/components/ui/atoms/Button/Button.vue';
import Card from '@/components/ui/atoms/Card/Card.vue';
import Input from '@/components/ui/atoms/Input/Input.vue';
import Spinner from '@/components/ui/atoms/Spinner.vue';
import Text from '@/components/ui/atoms/Text/Text.vue';
import { ensureStrongPassword, isValidEmail } from '@crewfare/server-shared';
import { validate } from 'uuid';
import { PropType } from 'vue';

export interface AgencySignupData {firstName: string, lastName: string, email: string, password: string}

export const validatePasswordSecurity = (password: string, submitAttempted: boolean)=>{
            if(submitAttempted && !password){
                return 'Password is required.';
            }
            else if(password && password.length < 8){
                return 'Password must be at least 8 characters.';
            }
            else if(password && password.length > 64){
                return 'Password must be at most 64 characters.';
            }
            else if(password && !ensureStrongPassword(password)){
                return 'Password must include an uppercase letter, lowercase letter, number, and special character.';
            }
            else{
                return '';
            }
        }

export default {
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            firstNameErr: '',
            lastNameErr: '',
            emailErr: '',
            passErr: '',
            submitAttempted: false 
        }
    },
    props: {
        signup: {
            type: Function as PropType<(data: AgencySignupData)=>void>,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
        signupError: {
            type: String,
            default: ''
        }
    },
    components: {
        Card,
        Text,
        Input,
        Button,
        Spinner
    },
    methods: {
        validateFirstName(){
            if(this.submitAttempted && !this.firstName){
                this.firstNameErr = 'First name is required.';
            }
            else{
                this.firstNameErr = '';
            }
        },
        validateLastName(){
            if(this.submitAttempted && !this.lastName){
                this.lastNameErr = 'Last name is required.';
            }
            else{
                this.lastNameErr = '';
            }
        },
        validateEmail(){
            if(this.submitAttempted && !this.email){
                this.emailErr = 'Email address is required.';
            }
            else if(this.email && !isValidEmail(this.email)){
                this.emailErr = 'Enter a valid email address.';
            }
            else{
                this.emailErr = '';
            }
        },
        validatePassword(){
            this.passErr = validatePasswordSecurity(this.password, this.submitAttempted);
        },
        validateFields(){
            this.validateFirstName()
            this.validateLastName()
            this.validateEmail()
            this.validatePassword()
            const hasError = this.emailErr || this.passErr;
            if(this.firstNameErr){
                console.log("First name ref", this.$refs.firstNameInput);
                (this.$refs.firstNameInput as any)?.focus();
            }
            else if(this.lastNameErr){
                console.log("Last name ref", this.$refs.lastNameInput);
                (this.$refs.lastNameInput as any)?.focus();
            }
            else if(this.lastNameErr){
                console.log("Last name ref", this.$refs.lastNameInput);
                (this.$refs.lastNameInput as any)?.focus();
            }
            else
            if(this.emailErr){
                console.log("Email ref", this.$refs.emailInput);
                (this.$refs.emailInput as any)?.focus();
            }
            else if(this.passErr){
                console.log("Password ref", this.$refs.passwordInput);
                (this.$refs.passwordInput as any)?.focus();
            }
            if(hasError){
                return false;
            }
            return true;
        },
        handleSubmit(){
            this.submitAttempted = true;
            if(this.loading || !this.validateFields()){
                return;
            }
            this.signup?.({
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                password: this.password
            });
        }
    }
}
</script>