<template>
    <Card size="medium" h-position="center" vPosition="start">
        <form @submit.prevent="null" class="w-full flex flex-col gap-y-10">
        <div class="flex items-center justify-center">
            <Icon name="mail" outFilled :width="56" />
        </div>
        <div class="flex flex-col gap-y-2">
            <Text align='center' size="32" thickness="bold" variant="h1">
                Email Sent
            </Text>
            <Text align='center' size="14" thickness="normal" variant="h2" color='gray'>
                Please check your email 
                <b>“{{ email }}”</b>
                for a password reset link.
            </Text>
        </div>
        <div class="w-full flex items-center flex-col gap-y-6">
            <Button @click="handleSubmit" size='full' theme="light">
                <span>Back to Login</span>
            </Button>
        </div>
    </form>
    </Card>
</template>
<script lang="ts">
import Button from '@/components/ui/atoms/Button/Button.vue';
import Card from '@/components/ui/atoms/Card/Card.vue';
import Icon from '@/components/ui/atoms/Icon/Icon.vue';
import Input from '@/components/ui/atoms/Input/Input.vue';
import Spinner from '@/components/ui/atoms/Spinner.vue';
import Text from '@/components/ui/atoms/Text/Text.vue';

export default {
    props: {
        email: {
            type: String,
        }
    },
    components: {
        Card,
        Text,
        Input,
        Button,
        Spinner,
        Icon
    },
    methods: {
        handleSubmit(){
            if(!this.$router) { 
                alert("It should take you to the login page");
                return;
            };
            this.$router.push('/');
        }
    }
}
</script>