<template>
    <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
        <li class="me-2" v-for="tab in tabs" :key="tab.key">
            <a href="#" @click="onTabChange(tab.key)" aria-current="page" :class="getClasses(tab.key)" class="flex items-center justify-center">
                <span>{{ tab.label }}</span>
                <InfoTip :size="'22px'" v-if="tab.info">
                    {{ tab.info }}
                </InfoTip>
            </a>
            
        </li>
    </ul>
</template>
<script lang="ts">
import { PropType } from 'vue';
import InfoTip from './InfoTip.vue';

export default {
    props: {
        tabs: {
            type: Array as PropType<{
                            key: string;
                            label: string;
                            info?: string;
                        }[]>,
            required: true
        },
        selected: {
            type: String,
            required: true
        },
        onTabChange: {
            type: Function as PropType<(key: string) => void>,
            required: true
        }
    },
    components: {
        InfoTip
    },
    methods: {
        getClasses(key: string){
            if(key === this.selected){
                return 'inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg active';
            }
            return 'inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50';
        }
    }
}
</script>