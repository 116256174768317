<template>
  <tr v-if="rfp">
    <td class="text-sm p-4">{{ rfp.hotel_name }} {{ rfp.contractMethod }}</td>
    <td class="text-sm p-4">{{ cutoffDateFormatted }}</td>
    <td class="text-sm p-4">
      <a
        v-if="rfp.contractMethod === 'external'"
        :href="`${downloadLink}`"
        target="_blank"
        class="text-crewfareGreen"
      >
        Contract
      </a>
      <router-link
        v-else
        :to="{
          name: 'rfpContract',
          params: { rfp: rfp.id },
        }"
        class="text-crewfareGreen"
      >
        Contract
      </router-link>
    </td>
    <td class="text-sm p-4">
      <SendMessage :rfp="rfp" />
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import SendMessage from "./SendMessage.vue";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";

export default {
  props: {
    rfp: {
      type: Object,
      required: true,
    },
  },
  components: {
    SendMessage,
  },
  data() {
    return {
      apiUrl: import.meta.env.VITE_API_URL,
    };
  },
  computed: {
    downloadLink() {
      let url = this.rfp.url || "";
      if (url.includes("firebasestorage")) {
        const storage = getStorage();
        const fileName = url.split("?")[0];
        const storageRef = ref(storage, fileName);
        getDownloadURL(storageRef).then((firebaseUrl) => {
          url = firebaseUrl;
        });
      }
      return url;
    },

    cutoffDateFormatted() {
      if (!this.rfp.cutoffDate) return "";
      return getMomentFromFirebaseDate(this.rfp.cutoffDate).format("D-M-Y");
    },
  },
};
</script>
