import { AccountDto } from '../../account/dto/update-account.dto';
import { AccountTypes } from '../typesAndEnums/AccountTypes';

export const getHotelUserTypes = () => {
  return [
    AccountTypes.Contact,
    AccountTypes.NSO,
    AccountTypes.Lead_Manager,
    AccountTypes.Group_Manager,
  ];
};

export const getSourceUserTypes = () => {
  return [AccountTypes.Super_Admin, AccountTypes.Admin, AccountTypes.Internal];
};

export const isHotelUser = (account: AccountDto) => {
  return getHotelUserTypes().includes(account.type.toLowerCase() as any);
};

export const isSourceUser = (account: AccountDto) => {
  return getSourceUserTypes().includes(account.type.toLowerCase() as any);
};

export const isSuperAdmin = (account: AccountDto) => {
  return account.type.toLowerCase() === AccountTypes.Super_Admin;
};

export const isHotelContact = (account: AccountDto) => {
  return account.type?.toLowerCase() === AccountTypes.Contact;
};

export const isHotelGroupManager = (account: AccountDto) => {
  return account.type?.toLowerCase() === AccountTypes.Group_Manager;
};
