<template>
  <button
    @click="() => passedFunction?.()"
    @drop.stop.prevent="event => onDropFunction?.(event)"
    @dragover.prevent
    @dragenter.prevent
    @dragleave.prevent
    @dragstart.prevent
    @dragend.prevent
    @drag.prevent
    :class="currentClass"
    class="px-6 py-3 rounded-[8px] flex gap-2 text-sm z-50"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { PropType } from 'vue';

export default {
  props: {
    passedFunction: Function,
    onDropFunction: Function,
    isDisabled: Boolean,
    theme: {
      type: String as PropType<'dark' | 'light'>,
      default: 'dark',
    },
    variant: {
      type: String as PropType<'primary' | 'secondary' | 'danger'>,
      default: 'primary',
    },
    size: {
      type: String as PropType<'contained' | 'full'>,
      default: 'contained',
    }
  },
  data() {
    return {};
  },
  computed: {
    currentClass(){
      const classMap = {
        'contained': '',
        'full': 'w-full flex justify-center items-center',
      }
      return {
        ...this.themeClasses,
        [classMap[this.size]]: true,
      };
    },
    themeClasses() {
      if(this.theme == 'dark'){
        if(this.variant == 'danger'){
          return {
            'font-semibold': true,
            'bg-crewfareRed text-white border border-crewfareRed hover:bg-white hover:text-crewfareRed active:bg-white active:text-white': !this.isDisabled,
            'bg-lpLightPurple text-white cursor-not-allowed border border-transparent': this.isDisabled,
          }
        }
        return {
          'bg-crewfareBlue text-white border border-crewfareBlue hover:bg-opacity-0 active:bg-opacity-0 active:text-white hover:text-white': !this.isDisabled,
          'bg-crewfareBlack text-white cursor-not-allowed border border-transparent':  this.isDisabled,
        }
      }
      else if(this.theme == 'light'){
        if(this.variant == 'primary'){
          return {
            'font-semibold': true,
            'bg-crewfareBlue text-white border border-crewfareBlue active:bg-lpWhite active:text-lpBlue hover:bg-lpWhite hover:text-lpBlue': !this.isDisabled,
            'bg-lpLightPurple text-white cursor-not-allowed border border-transparent': this.isDisabled,
            'hover:bg-lpWhite hover:text-lpBlue': !this.isDisabled,
          }
        }
        else if(this.variant == 'secondary'){
          return {
            'font-semibold': true,
            'bg-lpWhite border text-lpBlue border-lpBlue hover:bg-lpBlue hover:text-white active:text-white active:bg-lpBlue': !this.isDisabled,
            'bg-lpLightPurple text-white cursor-not-allowed border border-transparent': this.isDisabled,
          }
        }
        else if(this.variant == 'danger'){
          return {
            'font-semibold': true,
            'bg-lpRed text-white border border-lpRed hover:bg-lpRed hover:text-white active:bg-lpRed active:text-white': !this.isDisabled,
            'bg-lpLightPurple text-white cursor-not-allowed border border-transparent': this.isDisabled,
          }
        }
      }
    },
  },
};
</script>
