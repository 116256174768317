<template>
  <tr>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      {{ group.data().name }}
    </td>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      {{ Object.keys(group.data().hotelsIds).length }}
    </td>
    <td
      class="text-sm px-4 text-ellipsis overflow-hidden flex flex-col divide-y"
    >
      <span
        v-for="manager in group.data().manager"
        :key="manager.id"
        class="py-2"
      >
        {{ manager.name }} &lt;{{ manager.email }}&gt;
      </span>
    </td>
    <td class="px-4 py-2">
      <div class="flex gap-4 justify-end">
        <router-link
          v-tippy="'Edit group'"
          :to="{ name: 'hotelsGroupEdit', params: { group_id: group.id } }"
          class="text-crewfareGreen text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900"
        >
          edit
        </router-link>
        <button
          v-tippy="'Remove group'"
          @click="removeGroup"
          class="text-red-400 text-[18px] flex items-center px-4 material-symbols-rounded border rounded py-1 border-red-400 hover:bg-red-400 hover:text-white"
        >
          delete
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import { firestore } from "@/utils/firebase";
import { deleteDoc, doc } from "firebase/firestore";
import moment from "moment";

export default {
  props: ["group"],
  name: "User",
  computed: {
    permissions() {
      if (!this.account || !this.account.permissions) return "";
      if (
        this.account.permissions.length ===
        this.availablePermissions.length
      )
        return "All";
      return this.account.permissions?.join(", ") || "";
    },
    formatedLastLogin() {
      if (!this.account.last_login) return "";
      const last_login = getMomentFromFirebaseDate(this.account.last_login);
      return last_login.fromNow();
    },
    formatedCreatedAt() {
      if (!this.account.created_at) return "";
      const created_at = moment(this.account.created_at.toDate());
      return created_at.fromNow();
    },
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(
        `${import.meta.env.VITE_URL}/invite/${this.account.id}`
      );
      this.$store.commit("setToast", {
        content: `<p>Link copied!</p>`,
      });
    },
    async removeGroup() {
      if (!confirm("Delete this group?")) {
        return;
      }
      deleteDoc(doc(firestore, "hotel_groups", this.group.id));
      this.$store.commit("setToast", {
        content: `<p>Group Removed</p>`,
      });
      this.$emit("getData");
    },
  },
};
</script>
