<template>
    <img src="/logo.svg" :class="`${width}`" />
</template>
<script lang="ts">
import { PropType } from 'vue';

export default{
    computed: {
        width(){
            switch(this.size){
                case 'large':
                    return 'w-48';
                case 'medium':
                    return 'w-32';
                case 'small':
                    return 'w-28';
            }
        }
    },
    props: {
        size: {
            type: String as PropType<'large' | 'medium' | 'small'>,
            default: 'large'
        }
    }
}
</script>