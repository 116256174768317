<template>
  <Loading v-if="isLoading" size="md" />
  <div v-else class="flex flex-col gap-4">
    <div :class="classes.title">Hotel Information</div>
    <div class="flex flex-col gap-8">
      <div class="grid grid-cols-[1fr,1fr] gap-4 justify-center items-start">
        <Input
          v-model="hotelData.name"
          :placeholder="'Type Name'"
          :title="'Name*'"
          @update:model-value="checkHotel"
          :is-error="hotelExists"
          :error-message="hotelExists ? 'This hotel name already exists and will be duplicated if added' : ''"
        />
        <StarRating v-model="hotelData.rating" :title="'Rating'" />
      </div>
      <div class="grid grid-cols-[1fr,1fr] gap-4 justify-center items-start">
        <Select
          v-model="hotelData.chain"
          :options="chainsArray"
          :placeholder="'Select Chain'"
          :title="'Chain*'"
          :selected="hotelData.chain"
        />
        <Input
          v-model="hotelData.rooms"
          :placeholder="'Type Rooms Quantity'"
          :title="'Rooms'"
          @keypress="isNumber($event)"
        />
      </div>
      <Input
        :placeholder="'Type and Select Location'"
        :title="'Address*'"
        :icon-url="iconUrl"
        :onClickIcon="toggleManualAddress"
        input-filter="location"
        :location-function="setAddress"
        v-model="hotelData.addrFull"
      />
      <!-- Manual Address -->
      <template v-if="showManualAddress">
        <div class="flex gap-4 justify-center items-start">
          <Input
            v-model="hotelData.city"
            :placeholder="'Type City'"
            :title="'City*'"
            :is-bold-title="true"
            :isError="missingFields.city"
            :error-message="missingFields.city ? 'City is required' : ''"
            @input="validateField('city')"
          />
          <Input
            v-model="hotelData.country"
            :placeholder="'Type Country'"
            :title="'Country*'"
            :is-bold-title="true"
            :isError="missingFields.country"
            :error-message="missingFields.country ? 'Country is required' : ''"
            @input="validateField('country')"
          />
        </div>
        <div class="flex gap-4 justify-center items-start">
          <Input
            v-model="hotelData.state"
            :placeholder="'Type State'"
            :title="'State/Region*'"
            :is-bold-title="true"
            :is-error="missingFields.state"
            :error-message="missingFields.state ? 'State is required' : ''"
            @input="validateField('state')"
          />
          <Input v-model="hotelData.zipCode" :placeholder="'Type Zip Code'" :title="'Zip Code'" :is-bold-title="true" />
        </div>
        <div class="flex gap-4 justify-center items-start">
          <Input
            v-model="hotelData.lat"
            :placeholder="'Type Latitude'"
            :title="'Latitude*'"
            :is-bold-title="true"
            :isError="missingFields.lat"
            :error-message="missingFields.lat ? 'Latitude is required' : ''"
            @input="validateField('lat')"
          />
          <Input
            v-model="hotelData.lng"
            :placeholder="'Type Longitude'"
            :title="'Longitude*'"
            :is-bold-title="true"
            :isError="missingFields.lng"
            :error-message="missingFields.lng ? 'Longitude is required' : ''"
            @input="validateField('lng')"
          />
        </div>
      </template>
      <!-- --- -->
    </div>
    <!-- Contacts -->
    <div :class="classes.title" class="flex justify-between items-center mt-8">
      <div>Hotel Contacts</div>
      <img src="/icons/i-add.svg" class="w-5 h-5 cursor-pointer" @click="toggleAddContact" />
    </div>
    <div class="flex flex-col gap-8" v-if="addNewContact">
      <div class="grid grid-cols-[1fr,1fr] gap-4 justify-center items-start">
        <Input v-model="contactData.contactName" :placeholder="'Type Name'" :title="'Name*'" />
        <Input
          v-model="contactData.contactEmail"
          :placeholder="'Type Email*'"
          :title="'Email*'"
          :is-error="contactData.contactEmail ? !isValidEmail(contactData.contactEmail) : false"
          :error-message="contactData.contactEmail && !isValidEmail(contactData.contactEmail) ? 'Invalid Email' : ''"
        />
      </div>
      <div class="grid grid-cols-[1fr,1fr] gap-4 justify-center items-start">
        <Input
          v-model="contactData.contactPhone"
          :placeholder="'Type Phone'"
          :title="'Phone'"
          @keypress="isNumber($event)"
        />
      </div>
      <Button
        class="w-fit self-center"
        @click="isEditingContact ? editContactData(editingContactId) : handleNewContact()"
        :is-disabled="!areContactFieldsFilled"
        >{{ isEditingContact ? 'Edit Contact' : 'Add Contact' }}</Button
      >
    </div>
    <div v-if="contacts.length > 0" class="p-4 rounded-lg bg-crewfareBlack">
      <div class="grid grid-cols-[0.1fr,1fr,1fr,1fr,0.3fr] items-center gap-x-4">
        <div class="text-left">#</div>
        <div class="text-left">Name</div>
        <div class="text-left">Email</div>
        <div class="text-left">Phone</div>
        <div class="text-left"></div>
      </div>
      <div
        v-for="(contact, index) in contacts"
        :key="index"
        class="grid grid-cols-[0.1fr,1fr,1fr,1fr,0.3fr] items-center border-b border-crewfareGray-300 py-4 gap-x-4"
      >
        <div class="text-left">{{ `${index + 1}.` }}</div>
        <div class="text-left truncate">
          {{ contact.contactName }}
        </div>
        <div class="text-left truncate">
          {{ contact.contactEmail }}
        </div>
        <div class="text-left truncate">
          {{ contact.contactPhone }}
        </div>
        <div class="flex gap-2 w-full">
          <img src="/icons/i-pencil.svg" class="w-4 h-4 cursor-pointer" @click="fillWithChosenContact(index)" />
          <img src="/icons/i-trash.svg" class="w-4 h-4 cursor-pointer" @click="deleteContact(index)" />
        </div>
      </div>
    </div>
    <!-- --- -->
    <div class="w-full flex items-center justify-center">
      <Button
        @click="addHotel"
        class="w-fit px-[50px]"
        :is-disabled="!areFieldsFilled || isAddingHotel || isAddingContact"
      >
        <Loading v-if="isAddingHotel" />
        <span v-else>{{ editHotelId ? 'Save' : 'Add Hotel' }}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import { hotelsApi } from '@/utils/apis/hotelsApi';
import { debounce } from '@/utils/formatter';
import { isNumber } from '@/utils/inputs';
import { isValidEmail } from '@crewfare/utils';
import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Loading from '../atoms/Loading.vue';
import Select from '../atoms/Select.vue';
import StarRating from '../atoms/StarRating.vue';

export default {
  components: {
    Input,
    Button,
    Loading,
    Select,
    StarRating,
  },
  data() {
    return {
      classes: {
        title: 'text-white text-xl font-medium',
        warning: 'text-crewfareYellow',
      },
      showManualAddress: false,
      hotelData: {
        hotel_id: '',
        name: '',
        chain: this.chain || {},
        rooms: '',
        addrFull: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        lat: '',
        lng: '',
        rating: 1,
      },
      contactData: {
        contactName: '',
        contactRole: '',
        contactEmail: '',
        contactPhone: '',
      },
      addNewContact: true,
      contacts: [],
      isEditingContact: false,
      isAddingHotel: false,
      editingContactId: null,
      chainsArray: [],
      missingFields: {
        country: false,
        city: false,
        lat: false,
        lng: false,
        state: false,
      },
      hotelExists: false,
      editHotelData: null,
      isLoading: false,
    };
  },
  methods: {
    toggleManualAddress() {
      this.showManualAddress = !this.showManualAddress;
    },

    addHotel() {
      this.isAddingHotel = true;
      this.$emit('manual-data', {
        hotelData: {
          ...this.hotelData,
          chain: this.hotelData.chain.value,
        },
        contactData: this.contacts,
        isEdit: !!this.editHotelId,
        hotelId: this.editHotelId,
      });
    },
    setAddress(address) {
      this.missingFields.state = false;
      this.missingFields.country = false;
      this.missingFields.city = false;
      this.hotelData.addrFull = address.formatted_address;
      this.hotelData.addrFull = address.formatted_address;
      this.hotelData.lat = address.geometry.location.lat().toString();
      this.hotelData.lng = address.geometry.location.lng().toString();
      this.hotelData.city =
        address.address_components.find(component => component.types.includes('locality'))?.long_name ||
        address.address_components.find(component => component.types.includes('administrative_area_level_1'))
          ?.long_name;
      this.hotelData.state =
        address.address_components.find(component => component.types.includes('administrative_area_level_1'))
          ?.long_name || '';
      this.hotelData.country =
        address.address_components.find(component => component.types.includes('country'))?.long_name || '';
      this.hotelData.zipCode =
        address.address_components.find(component => component.types.includes('postal_code'))?.long_name || '';
      if (!this.hotelData.country.length || !this.hotelData.state.length) {
        this.missingFields.country = !this.hotelData.country.length;
        this.missingFields.state = !this.hotelData.state.length;
        this.missingFields.city = !this.hotelData.city.length;
        this.showManualAddress = true;
      }
      this.validateAllFields();
      this.checkHotel();
    },
    toggleAddContact() {
      if (this.isEditingContact && this.addNewContact) {
        this.contactData = {};
        this.isEditingContact = false;
        this.editingContactId = null;
      }
      this.addNewContact = !this.addNewContact;
    },
    handleNewContact() {
      this.contacts.push({
        contactName: this.contactData.contactName,
        contactRole: this.contactData.contactRole,
        contactEmail: this.contactData.contactEmail,
        contactPhone: this.contactData.contactPhone,
      });
      this.contactData = {};
      this.addNewContact = false;
    },
    fillWithChosenContact(id) {
      const contact = this.contacts[id];
      this.contactData = contact;
      this.isEditingContact = true;
      this.editingContactId = id;
      this.addNewContact = true;
    },
    editContactData(id) {
      this.contacts.splice(id, 1, this.contactData);
      this.contactData = {};
      this.isEditingContact = false;
      this.editingContactId = null;
      this.toggleAddContact();
    },
    deleteContact(id) {
      this.contacts.splice(id, 1);
    },
    validateField(field) {
      this.missingFields[field] = !this.hotelData[field];
    },
    validateAllFields() {
      Object.keys(this.missingFields).forEach(field => {
        this.validateField(field);
      });
      this.showManualAddress = Object.values(this.missingFields).some(val => val);
    },
    isNumber(event) {
      return isNumber(event);
    },
    isValidEmail(email) {
      return email.length ? isValidEmail(email) : true;
    },
    async getAllChains() {
        const chains = await hotelsApi.listChains().then(res => res.data);
        this.chainsArray = chains.map(chain => ({ value: chain, name: chain }));
    },
    checkHotel: debounce(async function () {
      this.hotelExists = false;
      if (this.hotelData.name && this.hotelData.addrFull) {
        await hotelsApi
          .checkIfHotelsExist(this.hotelData.name, this.hotelData.addrFull)
          .then(response => {
            this.hotelExists = response.data;
          })
          .catch(() => {
            this.hotelExists = false;
          });
      }
    }, 500),
  },
  computed: {
    iconUrl() {
      return this.showManualAddress ? '/icons/i-closed-eye.svg' : '/icons/i-open-eye.svg';
    },
    areFieldsFilled() {
      return (
        this.hotelData.name &&
        this.hotelData.addrFull &&
        this.hotelData.city &&
        this.hotelData.country &&
        this.hotelData.lat &&
        this.hotelData.lng &&
        this.hotelData.state &&
        this.checkChainValue
      );
    },
    areContactFieldsFilled() {
      return (
        this.contactData.contactName && this.contactData.contactEmail && isValidEmail(this.contactData.contactEmail)
      );
    },
    isAddingContact() {
      return (
        this.isEditingContact ||
        (this.addNewContact && !!(this.contactData.contactName?.length || this.contactData.contactEmail?.length))
      );
    },
    checkChainValue() {
      return !!this.hotelData.chain;
    },
  },
  props: {
    isNso: {
      type: Boolean,
      default: false,
    },
    chain: {
      type: Object,
      default: () => ({}),
    },
    editHotelId: {
      type: String,
      default: () => '',
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.editHotelId) {
      const response = await hotelsApi.get(this.editHotelId);
      this.hotelData = response.data;
      this.hotelData.addrFull = response.data.addrFull || response.data.addr || '';
      this.contacts =
        response.data.contacts?.map(contact => ({
          contactName: contact.name,
          contactEmail: contact.email,
          contactPhone: contact.phone || '',
          contactRole: contact.role || '',
        })) || [];
    }
    await this.getAllChains();
    this.isLoading = false;
  },
  beforeDestroy() {
    this.editHotelId = null;
  },
};
</script>
