import { BaseApi, generateQueryParams, getToken } from './apiFactory';

class HotelsApi extends BaseApi {
  listStates = async (search: string, signal: any) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/states?${generateQueryParams({ search })}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        signal,
      },
    });
  };

  listChains = async (forceFetch = false) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains?forceFetch=${forceFetch}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  listAccountChains = async () => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains/account`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  mergeToChain = async (chainId: string, hotelIds: string[]) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains/${chainId}/merge`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        hotelIds,
      },
    });
  };
  checkIfHotelsExist = async (name: string, address: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/check`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name,
        address,
      },
    });
  };
  listChainStats = async (options: any) => {
    const token = await getToken();
    return this.axios({
      url: options ? `${this.apiUrl}/chain-stats?${generateQueryParams(options)}` : `${this.apiUrl}/chain-stats`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  getChainById = async (id: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  createChain = async (name: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name,
      },
    });
  };
  removeChain = async (id: string) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  checkExistingNameHotels = async (names: string[]) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/check-existing-names`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        names,
      },
    });
  };
  createBulkChains = async (names: string[]) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/chains/bulk`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        names,
      },
    });
  };
  fetchByIds = async (ids: string[]) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/fetch-by-ids`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        ids,
      },
    });
  };
  fetchChainByNames = async (names: string[]) => {
    const token = await getToken();
    return this.axios({
      url: `${this.apiUrl}/fetch-chain-by-names`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        names,
      },
    });
  };
}

export const hotelsApi = new HotelsApi({ name: 'hotels' });
