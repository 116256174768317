<template>
    <Card size="medium" h-position="center" vPosition="start">
        <form @submit.prevent="null" class="w-full flex flex-col gap-y-10">
        <Text align='center' size="24" class="sm:text-40" thickness="bold" variant="h1">
            Login
        </Text>
        <div class="flex flex-col gap-y-6">
            <div class="flex flex-col gap-y-2">
                <Text color="thickGray" size="14" thickness="medium" variant='label'>Email Address</Text>
                <Input ref="emailInput" v-model="email" :error-message="emailErr" :is-error="!!emailErr" placeholder="e.g. john@company.com" variant="light" border='solid' @blur="validateEmail" />
            </div>
            <div class="flex flex-col gap-y-2">
                <div class="flex justify-between">
                    <Text color="thickGray" size="14" thickness="medium" variant='label'>Password</Text>
                    <a v-if="!isNew" href="/forgot-password"><Text variant="span" color="blue" thickness='semiBold' size="14">Forgot Password?</Text></a>
                </div>
                <Input ref="passwordInput" v-model="password" :error-message="passErr" :is-error="!!passErr" placeholder="•••••••••••••••••••" isPasswordInput variant="light" border='solid' @blur="validatePassword" />
            </div>
            <div v-if="isNew" class="flex flex-col gap-y-2">
                <div class="flex justify-between">
                    <Text color="thickGray" size="14" thickness="medium" variant='label'>Confirm Password</Text>
                </div>
                <Input ref="confPasswordInput" v-model="confPassword" :error-message="confPassErr" :is-error="!!confPassErr" placeholder="•••••••••••••••••••" isPasswordInput variant="light" border='solid' @blur="validateConfPassword" />
            </div>
        </div>
        <div class="w-full flex items-center flex-col gap-y-6">
            <Button @click="handleSubmit" size='full' theme="light">
                <span v-if="loading"><Spinner size="18px" /></span>
                <span v-else>{{isNew ? "Create Account": "Continue"}}</span>
            </Button>
            <Text size="14" thickness="normal" variant="span" color='gray'>
                Don't have an account? 
                <a href="/signup/hotel"><Text variant="span" color="blue" size="14"thickness='semiBold' > Sign Up</Text></a>
            </Text>
            <Text v-if="!loading && loginError" size="12" thickness="normal" variant="span" color="red">{{ loginError }}</Text>
        </div>
    </form>
    </Card>
</template>
<script lang="ts">
import Button from '@/components/ui/atoms/Button/Button.vue';
import Card from '@/components/ui/atoms/Card/Card.vue';
import Input from '@/components/ui/atoms/Input/Input.vue';
import Spinner from '@/components/ui/atoms/Spinner.vue';
import Text from '@/components/ui/atoms/Text/Text.vue';
import { isValidEmail } from '@crewfare/server-shared';
import { PropType } from 'vue';
import { validatePasswordSecurity } from '../CreateAccountCard/CreateAccountCard.vue';

export default {
    data() {
        return {
            email: '',
            password: '',
            confPassword: '',
            emailErr: '',
            passErr: '',
            confPassErr: '',
            submitAttempted: false 
        }
    },
    props: {
        login: {
            type: Function as PropType<(email: string, password: string)=>void>,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
        loginError: {
            type: String,
            default: ''
        },
        defaultEmail: String,
        isNew: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Card,
        Text,
        Input,
        Button,
        Spinner
    },
    watch: {
        defaultEmail() {
            console.log("Default email", this.defaultEmail);
            this.email = this.defaultEmail || "";
            (this.$refs.emailInput as any).inputValue = this.email
        }
    },
    methods: {
        validateEmail(){
            if(this.submitAttempted && !this.email){
                this.emailErr = 'Email address is required.';
            }
            else if(this.email && !isValidEmail(this.email)){
                this.emailErr = 'Enter a valid email address.';
            }
            else{
                this.emailErr = '';
            }
        },
        validatePassword(){
            if(this.isNew){
                this.passErr = validatePasswordSecurity(this.password, this.submitAttempted);
            }
            else if(this.submitAttempted && !this.password){
                this.passErr = 'Password is required.';
            }
            else{
                this.passErr = '';
            }
        },
        validateConfPassword(){
            if(this.submitAttempted && !this.confPassword){
                this.confPassErr = 'Confirm password is required.';
            }
            else if(this.confPassword !== this.password){
                this.confPassErr = 'Passwords do not match.';
            }
            else{
                this.confPassErr = '';
            }
        },
        validateFields(){
            this.validateEmail()
            this.validatePassword()
            this.isNew && this.validateConfPassword()
            const hasError = this.emailErr || this.passErr || this.confPassErr;
            if(this.emailErr){
                console.log("Email ref", this.$refs.emailInput);
                (this.$refs.emailInput as any)?.focus();
            }
            else if(this.passErr){
                console.log("Password ref", this.$refs.passwordInput);
                (this.$refs.passwordInput as any)?.focus();
            }
            else if(this.confPassErr){
                console.log("Confirm Password ref", this.$refs.confPasswordInput);
                (this.$refs.confPasswordInput as any)?.focus();
            }
            if(hasError){
                return false;
            }
            return true;
        },
        handleSubmit(){
            this.submitAttempted = true;
            if(this.loading || !this.validateFields()){
                return;
            }
            console.log('Login', this.email, this.password);
            this.login?.(this.email, this.password);
        }
    }
}
</script>