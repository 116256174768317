<template>
    <span v-if="iconsMap[name]" :class='{"flex items-center justify-center p-2.5 w-[7.5rem] h-[7.5rem] rounded-full": outFilled}' :style="{background: outfillColor}">
        <component :is="iconsMap[name]" :color="color" :width="width"  />
    </span>
</template>
<script lang="ts">
import { PropType } from 'vue';
import HomeIcon from './HomeIcon.vue';
import MailIcon from './MailIcon';
import LockIcon from './LockIcon';

export const iconsMap = {
    "home": HomeIcon,
    "mail": MailIcon,
    "lock": LockIcon
}
export default {
    computed: {
        iconsMap() {
            return iconsMap;
        }
    },
    props: {
        name: {
            type: String as PropType<"home" | "mail" | "lock">,
            required: true
        },
        width: {
            type: Number,
            default: 34
        },
        color: {
            type: String,
            default: '#3E24F6'
        },
        outFilled: {
            type: Boolean,
            default: false
        },
        outfillColor:{
            type: String,
            default: '#F5F4FE'
        }
    }
}
</script>