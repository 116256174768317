<template>
    <Wrapper :variant="'dark-bg-component'">
        <div class="flex flex-col gap-y-4 md:flex-row justify-between items-center text-crewfareWhite px-[7.5rem] py-[1.25rem]">
            <div>
                <Logo size="medium" />
            </div>
            <div v-if="!isSubmitted">
                <h3 class="text-center">Hotel Sign Up</h3>
            </div>
            <div v-if="!isSubmitted" class="flex flex-col text-center items-center md:flex-row gap-y-2">
                <img src="/icons/homepage.svg" />
                <h3><a href="https://crewfare.com">Go to Homepage</a></h3>
            </div>
        </div>
    </Wrapper>
</template>
<script lang="ts">
import Logo from '../../atoms/Logo';
import Wrapper from '../../atoms/Wrapper.vue';

export default {
    props:{
        isSubmitted: {
            type: Boolean,
            default: false
        }
    },
    components:{
        Wrapper,
        Logo
    }
}
</script>