<template>
    <AccountBg>
        <ForgotPassword v-if="!done" :reset="handleReset" :loading="loading" :reset-err="error" />
        <ResetEmailSent v-else :email="resetEmail" />
    </AccountBg>
    </template>
    <script lang="ts">
    import AccountBg from '@/components/ui/organism/Account/AccountBg/AccountBg.vue';
  import ForgotPassword from '@/components/ui/organism/Account/ForgotPassword/ForgotPassword.vue';
  import ResetEmailSent from '@/components/ui/organism/Account/ResetEmailSent/ResetEmailSent.vue';
  import { accountsApi } from '@/utils/apis/accountsApi';
    
    
    export default {
        data() {
            return {
                loading: false,
                error: '',
                done: false,
                resetEmail: ''
            }
        },
        components: {
            AccountBg,
            ForgotPassword,
            ResetEmailSent
        },
        methods:{
            handleReset(email: string){
                this.loading = true;
                this.error = "";
          accountsApi.requestPasswordReset(email)
            .then((data) => {
              this.loading = false;
              if(!data.error){
                this.done = true;
                this.resetEmail = email;
              }
              else{
                this.error = data.message || "We couldn’t find an account matching the email you entered. Please try again.";
              }
              
            })
            .catch(() => {
              this.loading = false;
              this.error =
                "We couldn’t find an account matching the email you entered. Please try again.";
            });
            }
        }
    }
    </script>