<template>
    <AccountBg>
        <CreateAccountCard :loading="loading" :signup-error="error" :signup="handleAgencySignup" />
    </AccountBg>
    </template>
    <script lang="ts">
    import AccountBg from '@/components/ui/organism/Account/AccountBg/AccountBg.vue';
    import ResetEmailSent from '@/components/ui/organism/Account/ResetEmailSent/ResetEmailSent.vue';
    import { accountsApi } from '@/utils/apis/accountsApi';
    import CreateAccountCard from '@/components/ui/organism/Account/CreateAccountCard';
    import { AgencySignupData } from '../../organism/Account/CreateAccountCard/CreateAccountCard.vue';
    
    
    export default {
        mounted(){
          document.title = 'Create Account | CrewFare';
        },
        data() {
            return {
                loading: false,
                error: '',
                done: false,
                resetEmail: ''
            }
        },
        components: {
            AccountBg,
            CreateAccountCard,
            ResetEmailSent
        },
        methods:{
            handleAgencySignup(data: AgencySignupData){
              this.loading = true;
              this.error = 'Not implemented yet';
              this.loading = false;
            }
        }
    }
    </script>