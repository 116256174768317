import { agencySignup } from './agency';
import { hotelSignup } from './hotel';

export const signups = [
  {
    path: '/signup',
    name: 'signup',
    children: [...agencySignup, ...hotelSignup],
  },
];
