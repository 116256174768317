<template>
    <Card size="medium" h-position="center" vPosition="start">
        <form @submit.prevent="null" class="w-full flex flex-col gap-y-10">
        <div class="flex flex-col gap-y-2">
            <Text align='center' size="24" class="sm:text-32" thickness="bold" variant="h1">
                Forgot Password
            </Text>
            <Text align='center' size="14" thickness="normal" variant="h2" color='gray'>
                Enter your email address to reset your password
            </Text>
        </div>
        <div class="flex flex-col gap-y-6">
            <div class="flex flex-col gap-y-2">
                <Text color="thickGray" size="14" thickness="medium" variant='label'>Email Address</Text>
                <Input ref="emailInput" v-model="email" :error-message="emailErr" :is-error="!!emailErr" placeholder="e.g. john@company.com" variant="light" border='solid' @blur="validateEmail" />
            </div>
        </div>
        <div class="w-full flex items-center flex-col gap-y-6">
            <Button @click="handleSubmit" size='full' theme="light">
                <span v-if="loading"><Spinner size="18px" /></span>
                <span v-else>Continue</span>
            </Button>
            <Text size="14" thickness="normal" variant="span">
                <a href="/"><Text variant="span" color="blue" size="14"thickness='semiBold' >Back to Login</Text></a>
            </Text>
            <Text v-if="!loading && resetErr" size="12" thickness="normal" variant="span" color="red">{{ resetErr }}</Text>
        </div>
    </form>
    </Card>
</template>
<script lang="ts">
import Button from '@/components/ui/atoms/Button/Button.vue';
import Card from '@/components/ui/atoms/Card/Card.vue';
import Input from '@/components/ui/atoms/Input/Input.vue';
import Spinner from '@/components/ui/atoms/Spinner.vue';
import Text from '@/components/ui/atoms/Text/Text.vue';
import { isValidEmail } from '@crewfare/server-shared';
import { PropType } from 'vue';

export default {
    data() {
        return {
            email: '',
            password: '',
            emailErr: '',
            passErr: '',
            submitAttempted: false 
        }
    },
    props: {
        reset: {
            type: Function as PropType<(email: string)=>void>,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
        resetErr: {
            type: String,
            default: ''
        }
    },
    components: {
        Card,
        Text,
        Input,
        Button,
        Spinner
    },
    methods: {
        validateEmail(){
            if(this.submitAttempted && !this.email){
                this.emailErr = 'Email address is required.';
            }
            else if(this.email && !isValidEmail(this.email)){
                this.emailErr = 'Enter a valid email address.';
            }
            else{
                this.emailErr = '';
            }
        },
        validatePassword(){
            if(this.submitAttempted && !this.password){
                this.passErr = 'Password is required.';
            }
            else{
                this.passErr = '';
            }
        },
        validateFields(){
            this.validateEmail()
            const hasError = this.emailErr || this.passErr;
            if(this.emailErr){
                console.log("Email ref", this.$refs.emailInput);
                (this.$refs.emailInput as any)?.focus();
            }
            else if(this.passErr){
                console.log("Password ref", this.$refs.passwordInput);
                (this.$refs.passwordInput as any)?.focus();
            }
            if(hasError){
                return false;
            }
            return true;
        },
        handleSubmit(){
            this.submitAttempted = true;
            if(this.loading || !this.validateFields()){
                return;
            }
            this.reset?.(this.email);
        }
    }
}
</script>