<template>
    <Card size="medium" h-position="center" vPosition="start">
        <form @submit.prevent="null" class="w-full flex flex-col gap-y-10">
            <div class="flex flex-col gap-y-2">
                <Text align='left' size="24" class="sm:text-32 sm:text-center" thickness="bold" variant="h1">
                    Set a New Password
                </Text>
                <Text align='left' size="14" class="sm:text-center" thickness="normal" variant="h2" color='gray'>
                    Enter your new password
                </Text>
            </div>
            <div class="flex flex-col gap-y-6">
                <div class="flex flex-col gap-y-2">
                    <Text color="thickGray" size="14" thickness="medium" variant='label'>Password</Text>
                    <Input isPasswordInput :is-disabled="invalid" ref="passInput" v-model="password" :error-message="passErr" :is-error="!!passErr" placeholder="Type here" variant="light" border='solid' @blur="validatePassword" />
                </div>
                <div class="flex flex-col gap-y-2">
                    <Text color="thickGray" size="14" thickness="medium" variant='label'>Re-enter New Password</Text>
                    <Input isPasswordInput :is-disabled="invalid" ref="confPassInput" v-model="confirmPassword" :error-message="confirmPassErr" :is-error="!!confirmPassErr" placeholder="Type here" variant="light" border='solid' @blur="validateConfirmPass" />
                </div>
            </div>
            <div class="w-full flex items-center flex-col gap-y-6">
                <Button :is-disabled="invalid" @click="handleSubmit" size='full' theme="light">
                    <span v-if="loading"><Spinner size="18px" /></span>
                    <span v-else>Continue</span>
                </Button>
                <Text size="14" thickness="normal" variant="span">
                    <a href="/"><Text variant="span" color="blue" size="14"thickness='semiBold' >Back to Login</Text></a>
                </Text>
                <Text v-if="!loading && resetErr" size="14" thickness="normal" variant="span" color="red">{{ resetErr }}</Text>
            </div>
        </form>
    </Card>
</template>
<script lang="ts">
import Button from '@/components/ui/atoms/Button/Button.vue';
import Card from '@/components/ui/atoms/Card/Card.vue';
import Input from '@/components/ui/atoms/Input/Input.vue';
import Spinner from '@/components/ui/atoms/Spinner.vue';
import Text from '@/components/ui/atoms/Text/Text.vue';
import { isValidEmail } from '@crewfare/server-shared';
import { invalid } from 'moment';
import { PropType } from 'vue';

export default {
    data() {
        return {
            password: '',
            confirmPassword: '',
            passErr: '',
            confirmPassErr: '',
            submitAttempted: false 
        }
    },
    props: {
        reset: {
            type: Function as PropType<(password: string)=>void>,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
        resetErr: {
            type: String,
            default: ''
        },
        invalid: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Card,
        Text,
        Input,
        Button,
        Spinner
    },
    methods: {
        validateConfirmPass(){
            if(this.submitAttempted && !this.confirmPassword){
                this.confirmPassErr = 'Please re-enter your password.';
            }
            else if(this.confirmPassword && this.confirmPassword !== this.password){
                this.confirmPassErr = 'Passwords do not match.';
            }
            else{
                this.confirmPassErr = '';
            }
        },
        validatePassword(){
            if(this.submitAttempted && !this.password){
                this.passErr = 'Password is required.';
            }
            else if(this.password && this.password.length < 8){
                this.passErr = 'Password must be at least 8 characters.';
            }
            else if(this.password && this.password.length > 64){
                this.passErr = 'Password must not exceed 64 characters.';
            }
            else{
                this.passErr = '';
            }
        },
        validateFields(){
            this.validatePassword();
            this.validateConfirmPass();
            const hasError =  this.passErr || this.confirmPassErr;
            if(this.passErr){
                console.log("Email ref", this.$refs.emailInput);
                (this.$refs.passInput as any)?.focus();
            }
            else if(this.confirmPassErr){
                console.log("Password ref", this.$refs.passwordInput);
                (this.$refs.confPassInput as any)?.focus();
            }
            if(hasError){
                return false;
            }
            return true;
        },
        handleSubmit(){
            if(this.invalid){
                return;
            }
            this.submitAttempted = true;
            if(this.loading || !this.validateFields()){
                return;
            }
            this.reset?.(this.password);
        }
    }
}
</script>