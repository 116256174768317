<template>
  <div>
    <div class="p-4 flex justify-between items-center">
      <div class="flex gap-4 items-center">
        <span>RFPS</span>
        <fwb-select
          v-model="statusType"
          :options="options"
          class="text-white bg-gray-700"
        />
        <fwb-select
          v-if="filterStatuses.length > 0"
          v-model="filterStatus"
          :options="filterStatuses"
          class="text-white bg-gray-700"
        />
        <input
          type="search"
          v-model="search"
          class="bg-gray-700 rounded px-4"
          placeholder="Search..."
        />
      </div>
    </div>
    <div class="flex flex-col rounded-lg pb-4 relative bg-[#23262F]">
      <div v-if="!loading && isLeadOrNSO" class="text-white">
        <div v-if="statusType === 3">
          <p class="text-xl px-4 py-2">Declined by Crewfare</p>
          <List
            v-if="rfpsDeclinedByCrewfare.length > 0"
            :rfps="rfpsDeclinedByCrewfare"
            :sortBy="sortBy" 
            :sortDirection="sortDirection"
            @sort="sort"
            @getData="getData"
            :currentPage="currentPage"
          />
          <p v-else class="my-4 text-center">
            There are no RFPs in this category at the moment.
          </p>
          <p class="text-xl px-4 py-2">Declined by the Hotel</p>
          <List
            v-if="rfpsDeclinedByHotel.length > 0"
            :rfps="rfpsDeclinedByHotel"
            :sortBy="sortBy" 
            :sortDirection="sortDirection"
            @sort="sort"
            @getData="getData"
            :currentPage="currentPage"
          />
          <p v-else class="my-4 text-center">
            There are no RFPs in this category at the moment.
          </p>
        </div>
        <div v-else>
          <List v-if="rfps?.length > 0" :rfps="rfps" :sortBy="sortBy" :sortDirection="sortDirection" @getData="getData" @sort="sort" :currentPage="currentPage" />
          <p v-else class="my-4 text-center">
            There are no RFPs in this category at the moment.
          </p>
        </div>
      </div>
      <div v-if="!loading && !isLeadOrNSO" class="text-white">
        <List v-if="rfps?.length > 0" :rfps="rfps" :sortBy="sortBy" :sortDirection="sortDirection" @sort="sort" @getData="getData" :currentPage="currentPage" />
        <p v-else class="my-4 text-center">
          There are no RFPs in this category at the moment.
        </p>
      </div>
      <div class="flex justify-center py-4">
        <span
          v-if="loading"
          class="material-symbols-rounded animate-spin text-[32px]"
        >
          autorenew
        </span>
      </div>
      <Pagination
      :actual="currentPage"
      :max="per_page"
      :total="total"
      @togglePage="togglePage"
    />
    </div>
  </div>
</template>

<script>
import List from "./List.vue";
import { FwbSelect } from "flowbite-vue";
import { ProposalStatusType } from "@crewfare/commons/src/model";
import { proposalsApi } from "@/utils/apis/proposalsApi";
import Pagination from "@/components/default/Pagination.vue";
import Input from "@/components/ui/atoms/Input";
import { debounce } from "@/utils/formatter";
import { getProposalStatusesList, ProposalStatus} from "@crewfare/server-shared/proposals";
import { titleCase, unique } from "@crewfare/utils";
import { SortDirection } from "@crewfare/server-shared";

export default {
  props: ["showViewAll"],
  components: {
    List,
    FwbSelect,
    Pagination,
    Input
  },
  data() {
    return {
      options: [
        {
          value: ProposalStatusType.ALL,
          name: "All RFPs",
        },
        {
          value: ProposalStatusType.PROGRESS,
          name: "RFPs in Progress",
        },
        {
          value: ProposalStatusType.COMPLETED,
          name: "Completed RFPs",
        },
        {
          value: ProposalStatusType.DECLINED,
          name: "Declined RFPs",
        },
      ],
      statusType: ProposalStatusType.ALL,
      rfps: [],
      loading: false,
      search: "",
      currentPage: 0,
      total: 0,
      status: ProposalStatusType.ALL,
      signals: {
        list: {
          controller: new AbortController(),
          count: 0,
        }
      },
      per_page: 10,
      filterStatus: ProposalStatusType.ALL,
      availableStatuses: [],
      sortBy: "updated_at",
      sortDirection: SortDirection.DESC,
    };
  },
  async mounted() {
    document.title = `Launchpad - Crewfare - RFPs`;
    this.currentPage = Math.max(0, parseInt(this.$route.query.page || 0));
    this.getData();
  },
  computed: {
    filterStatuses() {
      let newFilterStatuses = [{
        value: ProposalStatusType.ALL,
        name: this.statusType == ProposalStatusType.ALL ? "All Statuses" : `All ${this.statusType} statuses`,
      }];
      const statuses = getProposalStatusesList(this.statusType);
      Array.from(statuses).forEach((status) => {
        let statusName = titleCase(status)
        if(status == ProposalStatus.NEW)
          return;
        if(status == ProposalStatus.SENT){
          statusName = "New";
        }
        newFilterStatuses.push({
          value: status,
          name: statusName,
        })
      })
      return [...newFilterStatuses];
    },
    account() {
      return this.$store.state.account;
    },
    rfpsFound() {
      return this.rfps.length;
    },
    rfpsDeclinedByCrewfare() {
      return this.rfps.filter((rfp) => {
        return rfp.status.toLowerCase() === "declined by crewfare";
      });
    },
    rfpsDeclinedByHotel() {
      return this.rfps.filter((rfp) => {
        return rfp.status.toLowerCase() === "declined by hotel";
      });
    },
    isLeadOrNSO() {
      return ["nso", "lead manager"].includes(
        this.account.type.toLowerCase()
      );
    },
    isGroupManager() {
      return ["group manager"].includes(this.account.type.toLowerCase());
    },
    isContact() {
      return ["contact"].includes(this.account.type.toLowerCase());
    },
  },
  watch: {
    statusType() {
      this.filterStatus = ProposalStatusType.ALL;
      this.getData();
    },
    filterStatus() {
      this.getData();
    },
    status() {
      this.getData();
    },
    account() {
      this.getData();
    },
    search() {
      this.getData();
    }
  },
  methods: {
    sort(key, direction) {
      this.sortBy = key;
      this.sortDirection = direction;
      this.getData();

    },
    togglePage(page) {
      this.currentPage = page;
      //Push page number to the URL as query
      this.$router.push({ query: { page: page } });
      this.getData();
    },
    async listProposal(listCount, signal) {
      let proposalOptions = {
        filters: JSON.stringify({
          statusType: this.statusType,
          filterStatus: this.filterStatus,
        }),
        search: this.search,
        page: this.currentPage,
        per_page: this.per_page,
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      }
      const proposals = await proposalsApi.list(proposalOptions, signal).then((res) => res.data || []);
      if(listCount == this.signals.list.count){
        this.rfps = proposals.data;
        this.total = proposals.total;
        this.loading = false;
      }
      else{
        console.log("List count mismatch", listCount, this.signals.list.count)
      }
    },
    getData: debounce(function(){
      this.signals.list.count++;
      this.signals.list.controller = new AbortController();
      const currentCount = this.signals.list.count;
      this.loading = true;
      this.listProposal(currentCount, this.signals.list.controller.signal);
    }, 1000),
  },
};
</script>
