<template>
  <div class="flex rounded-full bg-crewfareGrey text-crewfareGreyLight overflow-hidden font-bold p-1">
    <div
      v-for="option in options"
      class="py-2.5 px-8 rounded-full cursor-pointer transition-all"
      :class="{ [classes.active]: option.id === selectedOption }"
      @click="select(option)"
    >
      {{ option.name }}
    </div>
  </div>
</template>

<script lang="ts">
import { Option } from '.'
export default {
  props: {
    options: Array<Option>,
    passedFunction: Function,
    defaultOption: String,
  },
  data() {
    return {
      classes: {
        active: 'bg-crewfarePurple text-white',
      },
      selectedOption: this.defaultOption,
    };
  },
  methods: {
    select(option: Option) {
      this.selectedOption = option.id;
      this.passedFunction?.(option);
    },
  },
};
</script>
