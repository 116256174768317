<template>
<Background variant="dark-v1">
    <div class="pt-14 pb-4 min-h-svh relative flex flex-col justify-between items-center sm:px-[7.5rem] px-4 gap-y-4">
        <div class="flex items-center flex-col gap-y-10 w-full">
            <Logo />
            <slot></slot>
        </div>
        <div class="flex flex-col-reverse md:flex-row justify-between w-full items-center gap-y-2">
            <Text color='lavenderGray' size="12" thickness="normal" variant="p">
                Copyright © {{ year }} Crewfare
            </Text>
            <div class="flex items-center gap-x-4">
                <Text color='lavenderGray' size="12" thickness="normal" variant="p">
                    <a>Terms & Conditions</a>
                </Text>
                <Text color='lavenderGray' size="12" thickness="normal" variant="p">
                    <ul class="list-disc">
                        <li><a>Privacy Policy</a></li>
                    </ul>
                </Text>
            </div>
        </div>
    </div>
</Background>
</template>
<script lang="ts">
import Background from '@/components/ui/atoms/Background/Background.vue';
import Logo from '@/components/ui/atoms/Logo/Logo.vue';
import Text from '@/components/ui/atoms/Text/Text.vue';

export default {
 computed:{
     year: ()=> new Date().getFullYear()
 },
 components: {
    Background,
    Logo,
    Text
 }
}
</script>