import { ProposalFilters } from '@crewfare/server-shared';
import { proposalsApi } from './apis/proposalsApi';
import { rfpsApi } from './apis/rfpsApi';

export interface ProposalSearch {
  filters?: ProposalFilters;
  search?: string;
  page?: string | number;
  per_page?: string | number;
  sortBy?: string;
  sortDirection?: string;
}

export const getProposal = async (proposalId: string, alertOnError = true) => {
  return proposalsApi.get(proposalId, alertOnError).then(_ => _.data);
};

export const listProposal = async (options: ProposalSearch = { per_page: 1000, page: 0 }, signal: any) => {
  return proposalsApi.list(options, signal).then(_ => _.data);
};

export const listProposalByRfpId = async (rfpId: string) => {
  return proposalsApi.list().then(_ => _.data);
};
