<template>
    <svg :width="width" :height="width" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.9193 17.4167V12.6667C26.9193 8.29441 23.3749 4.75 19.0026 4.75C14.6303 4.75 11.0859 8.29441 11.0859 12.6667V17.4167M13.9359 33.25H24.0693C26.7295 33.25 28.0596 33.25 29.0757 32.7323C29.9695 32.2769 30.6962 31.5502 31.1516 30.6565C31.6693 29.6404 31.6693 28.3102 31.6693 25.65V25.0167C31.6693 22.3564 31.6693 21.0263 31.1516 20.0102C30.6962 19.1164 29.9695 18.3898 29.0757 17.9344C28.0596 17.4167 26.7295 17.4167 24.0693 17.4167H13.9359C11.2757 17.4167 9.94556 17.4167 8.92948 17.9344C8.03571 18.3898 7.30905 19.1164 6.85366 20.0102C6.33594 21.0263 6.33594 22.3564 6.33594 25.0167V25.65C6.33594 28.3102 6.33594 29.6404 6.85366 30.6565C7.30905 31.5502 8.03571 32.2769 8.92948 32.7323C9.94556 33.25 11.2757 33.25 13.9359 33.25Z"
         :stroke="color" 
         stroke-width="1.5" 
         stroke-linecap="round" 
         stroke-linejoin="round"/>
    </svg>
</template>
<script>
export default {
    props: {
        color: {
            type: String,
            default: '#3E24F6'
        },
        width: {
            type: Number,
            default: 38
        }
    },
}
</script>