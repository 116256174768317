// nest/src/shared/utils/checkDuplicacy.ts
import { calculateDistance } from '@crewfare/server-shared' // Update path as needed
import { stringsKeysCreator } from '@crewfare/server-shared' // Update path as needed

// Define interfaces for the hotel objects
interface Location {
  lat: number
  lng: number
}

interface Hotel {
  location: Location
  addrFull: string
  searchableAddress: string
}

interface DuplicacyResult {
  isIn100MeterRadius: boolean
  addrIncludes: boolean
}

export function checkDuplicacy(
  baseHotel: Hotel,
  toCompareHotel: Hotel,
  calculateDistance: any
): DuplicacyResult {
  // Calculate distance between the two locations
  const distMeters = calculateDistance(
    baseHotel.location.lat,
    baseHotel.location.lng,
    toCompareHotel.location.lat,
    toCompareHotel.location.lng
  )

  // Check if within 100 meters
  const isIn100MeterRadius = distMeters <= 100

  // Check if the address includes part of the CSV hotel's address
  const addrIncludes = toCompareHotel.searchableAddress.includes(
    stringsKeysCreator(baseHotel.addrFull)
  )

  return {
    isIn100MeterRadius: isIn100MeterRadius || addrIncludes,
    addrIncludes
  }
}

export default {
  checkDuplicacy
}
