<template>
  <tr>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      {{ group.data().name }}
    </td>
    <td class="text-sm px-4 text-ellipsis overflow-hidden">
      {{ Object.keys(group.data().hotelsIds).length }}
    </td>
    <td
      class="text-sm px-4 text-ellipsis overflow-hidden flex flex-col divide-y"
    >
      <span
        v-for="manager in group.data().manager"
        :key="manager.id"
        class="py-2"
      >
        {{ manager.name }} &lt;{{ manager.email }}&gt;
      </span>
    </td>
    <td class="px-4 py-2">
      <div class="flex gap-4 justify-end">
        <button
          @click="assignGroup"
          class="text-green-400 flex items-center px-4 border rounded py-1 border-green-400 hover:bg-green-400 hover:text-white"
        >
          Assign RFP
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: ["group"],
  name: "User",
  methods: {
    assignGroup() {
      this.$emit("assignRFP", this.group);
    },
  },
};
</script>
