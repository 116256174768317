<template>
  <div
    v-if="rfp && hotel && account"
    class="bg-gray-800 w-full pb-4 flex flex-col gap-4 stage-height print:text-black"
  >
    <WelcomeDialog
      v-if="isHotel && showMessage && !hasBeenSigned"
      :hotel_name="rfp.hotel_name"
      @hide_dialog="showMessage = false"
    />
    <ContractFields
      v-if="editHotelFields || hasEmptyField || !contract_content"
      :isHotel="editHotelFields || isHotel"
      :editHotelFields="editHotelFields"
      :rfp="rfp"
      :account="account"
      @save="saveUpdateContract"
      :closeDialog="()=>{console.log('closing diaglor');this.editHotelFields = false}"
      @get_data="getData"
    />
    <div class="print:hidden sticky z-10 top-0 p-4 bg-gray-900">
      <div class="text-xl text-white gap-4 flex items-center justify-between">
        <div class="flex gap-2">
          RFP - {{ rfp.name }} - {{ rfp.hotel_name }} - Contract
        </div>
        <div class="flex gap-4 text-sm">
          <a
            :href="contractDownloadLink"
            target="_blank"
            class="text-sm whitespace-nowrap"
          >
            <span v-if="downloadingContract" class="flex items-center gap-2">
              <span class="material-symbols-rounded animate-spin text-[14px]">
                autorenew
              </span>
              Downloading agreement
            </span>
            <span v-else class="underline">Download Agreement</span>
          </a>
        </div>
      </div>
      <div class="py-2 flex text-white justify-between items-center">
        <div class="flex gap-4">
          <span class="flex text-xs">
            <span
              class="border rounded-l bg-crewfareGreen text-gray-900 border-crewfareGreen px-2 py-1"
            >
              Use Crewfare Contract
            </span>
            <span
              @click="toggleContractMethod"
              class="border cursor-pointer text-crewfareGreen rounded-r border-crewfareGreen px-2 py-1"
            >
              Use External Contract
            </span>
          </span>
          <ResumeDialog :rfp="rfp" />
        </div>
        <ContractInternalHistoryDialog
          v-if="contract_history"
          :contract_history="contract_history"
          @get_data="getData"
        />
      </div>
      <div
        v-if="isCrewfare"
        class="py-2 flex text-white justify-between items-center"
      >
        <div class="flex gap-4">
          <router-link
            :to="{
              name: 'rfpView',
              params: { rfp: rfp.id },
              query: { admin: true },
            }"
            class="border text-xs rounded text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen px-2 py-1"
          >
            Update RFP
          </router-link>
          <button
            @click="() => restartSigningProcess()"
            class="border text-xs rounded text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen px-2 py-1"
          >
            Restart Signing Process
          </button>
          <button
            v-if="isCrewfare && !(hasEmptyField || !contract_content)"
            @click="() => this.editHotelFields = true"
            class="border text-xs rounded text-crewfareGreen hover:bg-crewfareGreen hover:text-gray-900 border-crewfareGreen px-2 py-1"
          >
            Edit Hotel Fields
          </button>
        </div>
      </div>
    </div>
    <ContractHints :proposal="rfp" />
    <div>
      <div class="mx-4">
        <Editor
          v-if="rfp && hotel && contract_content"
          :rfp="rfp"
          :hotel="hotel"
          :signed="signed"
          :contract_content="contract_content"
          :has_comments="has_comments"
          :has_changes="has_changes"
          @initChanges="init_changes"
          @hasChanges="toggleHasChanges"
          @acceptChange="acceptChange"
          @rejectChange="rejectChange"
          @saveComment="saveComments"
        />
      </div>
      <div v-if="has_changes || has_comments">
        <div class="flex gap-4 mt-4 px-4 justify-end print:hidden">
          <div class="flex gap-4 items-center">
            <button
              v-if="!needToResolve"
              @click="editContract"
              class="green-button disabled:bg-gray-200 disabled:text-black disabled:border-gray-200 gap-2"
            >
              <span v-if="updatingContract" class="flex items-center">
                <span class="material-symbols-rounded animate-spin">
                  autorenew
                </span>
              </span>
              Save and Send for Review
            </button>
            <div class="w-full text-white px-4" v-else>Please resolve {{ isHotel ? "Crewfare's": "hotel's" }} changes first</div>
          </div>
        </div>
      </div>
      <div v-else-if="Object.keys(myChanges).length > 0 || this.isContractReviewGoing">
        <div v-if="(!allSelfChangesDeclined)&&(isHotel === rfp.isHotel)">
          <p class="text-center text-white mt-6">Waiting for approval.</p>
        </div>
        <div v-else class="flex gap-4 mt-4 px-4 justify-end print:hidden">
          <div
            class="flex gap-4 items-center"
          >
            <button
              v-if="!needToResolve"
              @click="acceptContractTerms"
              class="green-button disabled:bg-gray-200 disabled:text-black disabled:border-gray-200"
            >
              <span v-if="signingContract" class="flex items-center">
                <span class="material-symbols-rounded animate-spin">
                  autorenew
                </span>
              </span>
              Accept New Terms
            </button>
            <div class="w-full text-white px-4" v-else>Please resolve {{ isHotel ? "Crewfare's": "hotel's" }} changes first</div>
          </div>
        </div>
      </div>
      <div v-else class="w-full text-white px-4" ref="document_signature">
        <table
          cellpadding="0"
          cellspacing="0"
          style="margin-top: 16px"
          class="w-full"
        >
          <tr>
            <td width="400px" valign="top">
              <div class="flex gap-4 flex-col max-w-[400px]">
                <div style="margin-top: 12px">GROUP: CREWFARE LLC</div>
                <div class="flex flex-col gap-1">
                  <span>Signature:</span>
                  <div
                    v-if="
                      !regenerating &&
                      !isFullySigned &&
                      (hide_signature || isCrewfare)
                    "
                    class="flex flex-col gap-2"
                  >
                    <div
                      class="bg-white rounded"
                      ref="signaturePadCrewfareHolder"
                    >
                      <VueSignaturePad
                        width="400px"
                        height="160px"
                        ref="signaturePadCrewfare"
                      />
                    </div>
                    <div v-if="!isSigned" class="flex justify-between">
                      <button
                        @click="undo('crewfare')"
                        class="red-button-small"
                      >
                        Clear
                      </button>
                      <button
                        @click="save('crewfare')"
                        class="green-button-small gap-2"
                      >
                        <span v-if="savingSign" class="flex items-center">
                          <span
                            class="text-[10px] material-symbols-rounded animate-spin"
                          >
                            autorenew
                          </span>
                        </span>
                        Save
                      </button>
                    </div>
                  </div>
                  <img
                    v-if="isFullySigned || regenerating || isHotel"
                    :src="crewfare_signature"
                    class="rounded bg-white"
                    style="width: 350px"
                  />
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Name:</span>
                  <span class="w-full">
                    <input
                      v-if="isCrewfare && !hasBeenSigned"
                      type="text"
                      v-model="crewfare_name"
                      @input="validateInputs"
                      @blur="validateInputs"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        crewfare_name.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ crewfare_name }}</span
                    >
                  </span>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Title:</span>
                  <span class="w-full">
                    <input
                      v-if="isCrewfare && !hasBeenSigned"
                      type="text"
                      v-model="crewfare_title"
                      @input="validateInputs"
                      @blur="validateInputs"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        crewfare_title.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ crewfare_title }}</span
                    >
                  </span>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Date:</span>
                  <span class="w-full">
                    <input
                      v-if="isCrewfare && !hasBeenSigned"
                      type="text"
                      v-model="crewfare_date"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      @change="validateDate(crewfare_date)"
                      :class="
                        crewfare_date.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ crewfare_date }}</span
                    >
                  </span>
                </div>
              </div>
            </td>
            <td width="40px"></td>
            <td width="400px" valign="top">
              <div class="flex gap-4 flex-col max-w-[400px]">
                <div style="margin-top: 12px">
                  HOTEL: {{ hotel.name }}
                </div>
                <div class="flex flex-col gap-1">
                  <span>Signature:</span>
                  <div
                    v-if="
                      !regenerating &&
                      !isFullySigned &&
                      (hide_signature || isHotel)
                    "
                    class="flex flex-col gap-2"
                  >
                    <div
                      class="bg-white rounded max-w-[400px]"
                      ref="signaturePadHotelHolder"
                    >
                      <VueSignaturePad
                        width="400px"
                        height="160px"
                        ref="signaturePadHotel"
                      />
                    </div>
                    <div v-if="!isSigned" class="flex justify-between">
                      <button @click="undo('hotel')" class="red-button-small">
                        Clear
                      </button>
                      <button
                        @click="save('hotel')"
                        class="green-button-small gap-2"
                      >
                        <span v-if="savingSign" class="flex items-center">
                          <span
                            class="text-[10px] material-symbols-rounded animate-spin"
                          >
                            autorenew
                          </span>
                        </span>
                        Save
                      </button>
                    </div>
                  </div>
                  <div
                    v-if="regenerating || isFullySigned || isCrewfare"
                    class="bg-white"
                  >
                    <img
                      :src="hotel_signature"
                      class="rounded bg-white"
                      style="width: 350px"
                    />
                  </div>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Name:</span>
                  <span class="w-full">
                    <input
                      v-if="isHotel && !hasBeenSigned"
                      type="text"
                      v-model="hotel_person_name"
                      @input="validateInputs"
                      @blur="validateInputs"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        hotel_person_name.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ hotel_person_name }}</span
                    >
                  </span>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Title:</span>
                  <span class="w-full">
                    <input
                      v-if="isHotel && !hasBeenSigned"
                      type="text"
                      v-model="hotel_title"
                      @input="validateInputs"
                      @blur="validateInputs"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        hotel_title.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ hotel_title }}</span
                    >
                  </span>
                </div>
                <div
                  style="margin-top: 12px"
                  class="flex items-center gap-2 flex-col"
                >
                  <span class="text-left w-full">Date:</span>
                  <span class="w-full">
                    <input
                      v-if="isHotel && !hasBeenSigned"
                      type="text"
                      v-model="hotel_date"
                      class="w-full bg-gray-600 py-2 px-4 rounded"
                      :class="
                        hotel_date.length < 1
                          ? 'border-yellow-200'
                          : 'border-gray-600'
                      "
                    />
                    <span v-else class="text-yellow-200 print:text-yellow-900"
                      >&nbsp;{{ hotel_date }}</span
                    >
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div
      v-if="!isFullySigned && rfp.status !== 'Update Bid Terms'"
      class="flex gap-4 mt-4 px-4 justify-between print:hidden"
    >
      <div style="margin-top: 12px">
        <a
          :href="contractDownloadLink"
          target="_blank"
          class="green-button"
        >
          Download Agreement Here
        </a>
      </div>
      <div
        v-if="!has_comments && (isHotel || isCrewfare) && !isSigned"
        class="flex gap-4 items-center"
      >
        <div v-if="error" class="text-red-200">
          Please verity your signature and if there are missing fields on your
          contract
        </div>
        <button
          v-if="!has_changes"
          @click="accept"
          class="green-button disabled:bg-gray-200 disabled:text-black disabled:border-gray-200"
        >
          <span v-if="signingContract" class="flex items-center">
            <span class="material-symbols-rounded animate-spin">
              autorenew
            </span>
          </span>
          Sign Contract
        </button>
      </div>
    </div>
    <div
      v-if="rfp.status.toLowerCase() === 'signed by both sides'"
      class="text-center mt-4 flex items-center gap-4 flex-col justify-center"
    >
      <div class="text-white">This contract is fully signed.</div>
      <div v-if="isCrewfare" class="flex gap-4">
        <button @click="getContract" class="green-button gap-2">
          <span
            v-if="regenerating"
            class="material-symbols-rounded animate-spin"
          >
            autorenew
          </span>
          Update contract
        </button>
        <button @click="regenerateContract" class="green-button gap-2">
          <span
            v-if="regenerating"
            class="material-symbols-rounded animate-spin"
          >
            autorenew
          </span>
          Send to execute
        </button>
      </div>
    </div>
    <div class="hidden">
      <div
        ref="rfpResume"
        class="overflow-auto max-h-[400px] flex flex-col gap-2 text-base"
      >
        <strong class="px-4 text-xl mb-2">{{ rfp.name }}</strong>
        <strong class="px-4" style="margin-top: 12px"
          >Property name: {{ rfp.hotel_name }}</strong
        >
        <strong class="px-4" style="margin-top: 12px"
          >Name of client (sourcing): Crewfare</strong
        >
        <strong class="px-4" style="margin-top: 12px"
          >Date downloaded: {{ todayFormated }}</strong
        >
        <div v-if="rfp.attrition" class="px-4" style="margin-top: 12px">
          Currency:
          <strong>{{ rfp.defaultCurrency || "$ USD" }}</strong>
        </div>
        <div
          v-for="(roomItem, index) in rfp.roomList"
          :key="index"
          class="flex gap-2 px-4"
          style="margin-top: 12px"
        >
          <div class="flex flex-col gap-2">
            <div>
              Date: <strong>{{ roomItem.date }}</strong>
            </div>
            <div v-for="(room, roomIndex) in roomItem.rooms">
              {{ room.amount }} {{ room.type }} -
              {{ rfp.defaultCurrency || "$ USD" }} ${{ room.value }}
            </div>
          </div>
        </div>
        <strong class="px-4" style="margin-top: 12px">Occupancy</strong>
        <div class="px-4" v-for="room in rfp.roomTypes">
          {{ room.name }} - Occuppancy: {{ room.occupancy }} - Value:
          {{ rfp.defaultCurrency || "$ USD" }} ${{ room.additional }}
        </div>
        <div class="grid grid-cols-3 gap-4 p-4 w-full">
          <div v-if="rfp.attrition" style="margin-top: 12px">
            Attrition:
            <strong>{{ rfp.attrition }}</strong>
          </div>
          <div v-if="rfp.cutoffDate" style="margin-top: 12px">
            Cut-Off Date:
            <strong>{{ cutoffDateFormatted }}</strong>
          </div>
          <div
            v-if="rfp.commission"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <span>
              Commission(%):
              <strong>{{ rfp.commission }}</strong>
            </span>
            <div v-if="rfp.commission === 'Non Commissionable'">
              Commission Amount:
              <strong>{{ rfp.commissionAmount }}</strong>
            </div>
          </div>
          <div v-if="rfp.iata" style="margin-top: 12px">
            IATA #:
            <strong>{{ rfp.iata }}</strong>
          </div>
          <div
            v-if="rfp.rebate"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <span>
              Rebate: <strong>{{ rfp.rebate }}</strong>
            </span>
            <span>
              Rebate Type: <strong>{{ rfp.rebateType }}</strong>
            </span>
          </div>
          <div
            v-if="rfp.groupDateHonored"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Group Rate Honored:
              <strong>{{ rfp.groupDateHonored }}</strong>
            </div>
            <div v-if="rfp.groupDateHonored === 'No'">
              <div>
                <span class="flex px-2 items-center justify-center">$</span>
                <span>
                  {{ rfp.groupDateHonoredPreRate }}
                </span>
              </div>
              <div>
                <span class="flex px-2 items-center justify-center">$</span>
                <span>
                  {{ rfp.groupDateHonoredPostRate }}
                </span>
              </div>
            </div>
            <div>
              <div v-if="rfp.groupDateHonoredPre">
                Days Pre Event:
                <strong>{{ rfp.groupDateHonoredPreDays }}</strong>
              </div>
              <div v-if="rfp.groupDateHonoredPost">
                Days Post Event
                <strong>{{ rfp.groupDateHonoredPostDays }}</strong>
              </div>
            </div>
          </div>
          <div v-if="rfp.compRooms" style="margin-top: 12px">
            Comp Rooms:
            <strong>{{ rfp.compRooms }}</strong>
          </div>
          <div v-if="rfp.suiteUpgrades" style="margin-top: 12px">
            Suite Upgrades:
            <strong>{{ rfp.suiteUpgrades }}</strong>
          </div>
          <div v-if="rfp.rewardPoints" style="margin-top: 12px">
            Reward Points:
            <strong>{{ rfp.rewardPoints }}</strong>
          </div>
          <div v-if="rfp.staffDiscount" style="margin-top: 12px">
            Staff Rooms:
            <strong>{{ rfp.staffRooms }}</strong>
          </div>
          <div
            v-if="rfp.staffDiscount"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Staff Discount:
              <strong>{{ rfp.staffDiscount }}</strong>
            </div>
            <div v-if="rfp.staffDiscount === 'Other'">
              Staff Discount Amount:
              <strong>{{ rfp.staffDiscountAmount }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.resortFee"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Resort Fee:
              <strong>{{ rfp.resortFee }}</strong>
            </div>
            <div v-if="rfp.resortFee === 'Resort Fee'">
              Resort Fee Amount:
              <strong>{{ rfp.resortFeeAmount }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.billing"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Reservation Submission:
              <strong>{{ rfp.reservationSubmission }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.billing"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Billing:
              <strong>{{ rfp.billing }}</strong>
            </div>
            <div v-if="rfp.billing === 'Other'">
              Other: <strong>{{ rfp.billingsOther }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.incidentals"
            class="flex flex-col gap-2"
            style="margin-top: 12px"
          >
            <div>
              Incidentals:
              <strong>{{ rfp.incidentals }}</strong>
            </div>
            <div v-if="rfp.incidentals === 'Other'">
              Other: <strong>{{ rfp.incidentalsOther }}</strong>
            </div>
          </div>
          <div
            v-if="rfp.wifi"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Wi-Fi:
              <strong>{{ rfp.parking }}</strong>
            </div>
            <div v-if="rfp.wifi === 'Not included'">
              Price:
              <strong>
                {{ rfp.wifi_price }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.parking"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Parking:
              <strong>{{ rfp.parking }}</strong>
            </div>
            <div v-if="rfp.parking === 'Not included'">
              Price:
              <strong>
                {{ rfp.parkingOther }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.breakfast"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Breakfast:
              <strong>{{ rfp.breakfast }}</strong>
            </div>
            <div v-if="rfp.breakfast === 'Not included'">
              Price:
              <strong>
                {{ rfp.breakfastOther }}
              </strong>
            </div>
          </div>
          <div
            v-if="rfp.otherFees"
            class="flex flex-col gap-2 w-full"
            style="margin-top: 12px"
          >
            <div>
              Other Fees:
              <strong>{{ rfp.otherFees }}</strong>
            </div>
            <div v-if="rfp.otherFees === 'Yes'">
              Price:
              <strong>
                {{ rfp.otherFeesAmount }}
              </strong>
            </div>
          </div>
        </div>
        <div v-if="rfp.terms" class="p-4 flex flex-col gap-1">
          <div>Additional Terms (Optional)</div>
          <div v-html="rfp.terms"></div>
        </div>
      </div>
    </div>
    <div
      v-if="signErrorModal"
      class="z-40 fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center"
    >
      <div
        class="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center z-[100] bg-black/50"
      ></div>
      <div
        class="bg-gray-900 z-[101] rounded-lg shadow-md py-4 text-white flex flex-col gap-4 justify-center p-4 items-center"
      >
        <p class="text-sm text-center px-4">
          Please click save under signature in order to send contract
        </p>
        <div class="flex px-4">
          <button @click="signErrorModal = false" class="cancel-button">
            Dismiss
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jasonSignature } from "@/assets/jason-signature.jsx";
import { ResumeDialog } from "@/components/rfp";
import ContractBoston from "@/components/rfp/contract/ContractBoston.vue";
import ContractDagny from "@/components/rfp/contract/ContractDagny.vue";
import ContractFields from "@/components/rfp/contract/ContractFields.vue";
import ContractHints from "@/components/rfp/contract/ContractHints.vue";
import ContractInternalHistoryDialog from "@/components/rfp/contract/ContractInternalHistoryDialog.vue";
import ContractMarriot from "@/components/rfp/contract/ContractMarriot.vue";
import DefaultContract from "@/components/rfp/contract/DefaultContract.vue";
import Editor from "@/components/rfp/contract/Editor.vue";
import RequestEditDialog from "@/components/rfp/contract/RequestEditDialog.vue";
import WelcomeDialog from "@/components/rfp/contract/WelcomeDialog.vue";
import { contractExecutedEmailContent, contractExecutedSubject } from "@/emails/contract-executed";
import { contractExecutedInternalEmailContent, contractExecutedInternalSubject } from "@/emails/contract-executed-internal";
import { contractSignedHotelEmailContent, contractSignedHotelSubject } from "@/emails/contract-signed-hotel";
import { contractSignedInternalEmailContent, contractSignedInternalSubject } from "@/emails/contract-signed-internal";
import router from "@/router";
import { getMomentFromFirebaseDate } from "@/utils/dateUtils";
import { processVariables, sendEmail } from "@/utils/emails.jsx";
import { firestore } from "@/utils/firebase";
import {
  sendNotificationAdmin,
  sendNotificationHotels,
} from "@/utils/notifications.jsx";
import { getRFP, setStatusHistory } from "@/utils/rfp.jsx";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import { getNsoByChain, sendNsoCommunication } from "@/utils/nso";
import { unique } from "@crewfare/utils";
import { templatesApi } from "@/utils/apis/templatesApi";
import Button from "@/components/ui/atoms/Button";
import { DEFAULT_CREWFARE_CONTRACT } from "@/constants/contract.constant";
import { isCrewfareAccount } from "@/utils/account";
import { NotificationTypes } from "@/enums/NotificationTypes";
import { ViewsLocations } from "@crewfare/server-shared";
import { contractsApi } from "@/utils/apis/contractsApi";
import { rfpsApi } from "@/utils/apis/rfpsApi";
import { hotelsApi } from "@/utils/apis/hotelsApi";
import { ProposalStatus } from "@crewfare/server-shared/proposals";

export default {
  components: {
    ResumeDialog,
    WelcomeDialog,
    DefaultContract,
    ContractDagny,
    ContractFields,
    ContractHints,
    Editor,
    ContractBoston,
    RequestEditDialog,
    ContractMarriot,
    ContractInternalHistoryDialog,
    Editor,
    Button,
  },
  data() {
    return {
      generatingPDF: false,
      editor: null,
      has_comments: false,
      signErrorModal: false,
      apiUrl:
        import.meta.env.VITE_API_URL ||
        "https://dev-api-launchpad.crewfare.com",
      appUrl: import.meta.env.VITE_URL || "https://launchpad.crewfare.com",
      hide_signature: false,
      regenerating: false,
      init: null,
      activeComment: null,
      contractMethod: null,
      showMessage: true,
      savingFields: false,
      savingSign: false,
      error: false,
      crewfareHasSigned: false,
      signingContract: false,
      hotelHasSigned: false,
      downloadingContract: false,
      contract_content: null,
      contract_content_edit: null,
      hotel_signature: "",
      hotel_name: "",
      hotel_person_name: "",
      hotel_address: "",
      hotel_email: "",
      hotel_telephone: "",
      hotel_title: "",
      hotel_date: "",
      crewfare_date: "",
      crewfare_signature: "",
      crewfare_name: "Jason van Esso",
      crewfare_title: "Partner",
      crewfare_members: "",
      hotel_taxes: "",
      hotel_other_fees: "",
      loadingAccept: true,
      message: null,
      rfp: null,
      parentRfp: null,
      hotel: null,
      contract_history: null,
      signed: [],
      editor_enabled: false,
      contract: "",
      commentButton: false,
      selection: "",
      loadingSigned: false,
      terms: "",
      signatureCrewfare: null,
      signatureHotel: null,
      has_comments: false,
      toggleRequestEdit: false,
      updatingContract: false,
      changesInitiated: false,
      originalChanges: {},
      acceptedChanges: {},
      hoteldeclinedChanges: {},
      crewfareDeclinedChanges: {},
      hotelChanges: {},
      crewfareChanges: {},
      newChanges: {},
      editHotelFields: false
    };
  },
  computed: {
    isContractReviewGoing(){
      return this.rfp.status.toLowerCase() === ProposalStatus.UPDATE_BID_TERMS
    },
    relatedEmails(){
      return [...new Set(
        [...(this.rfp?.relatedEmails||[]) ,
        ...(this.parentRfp?.ccEmails || [])]
      )]
    },
    contractDownloadLink(){
      let nest_api_url = import.meta.env.VITE_NEST_API_URL
      return `${nest_api_url}/contract/${this.rfp.id}/download`
    },
    contractDownloadAsJSONLink(){
      let nest_api_url = import.meta.env.VITE_NEST_API_URL
      return `${nest_api_url}/contract/${this.rfp.id}/download?inJSON=true`
    },
    myChanges(){
      return this.isHotel ? this.hotelChanges : this.crewfareChanges
    },
    othersDeclinedChanges(){
      return this.isHotel ? this.crewfareDeclinedChanges : this.hoteldeclinedChanges
    },
    myDeclinedChanges(){
      return this.isHotel ? this.hoteldeclinedChanges : this.crewfareDeclinedChanges
    },
    othersChanges(){
      return this.isHotel ? this.crewfareChanges : this.hotelChanges
    },
    needToResolve(){
      return Object.keys(this.othersChanges).length !== Object.keys(this.acceptedChanges).length + Object.keys(this.othersDeclinedChanges).length
    },
    allSelfChangesDeclined(){
      const myChangesLen = Object.keys(this.myChanges).length
      if( !myChangesLen ) return false
      return myChangesLen === Object.keys(this.myDeclinedChanges).length
    },
    has_changes(){
      console.log("has_change status",{
        original: this.originalChanges,
        hotelChanges: this.hotelChanges,
        crewfareChanges: this.crewfareChanges,
        newChanges: this.newChanges,
        acceptedChange: this.acceptedChanges,
        myChanges: this.myChanges,
        myDeclined: this.myDeclinedChanges,
        othersDeclined: this.othersDeclinedChanges
      })
      let onlySomeOfOwnChangesDeclined = Object.keys(this.myDeclinedChanges).length > 0 && !this.allSelfChangesDeclined
      //Send for review in case there's new change, or declined some other's change or self existing change is there
      return (Object.keys(this.newChanges).length + Object.keys(this.othersDeclinedChanges).length) || onlySomeOfOwnChangesDeclined
    },
    todayFormated() {
      return moment().format("ddd MMM. DD, YYYY");
    },
    cutoffDays() {
      const date = getMomentFromFirebaseDate(this.rfp.startDate);
      const cutoff = getMomentFromFirebaseDate(this.rfp.cutoffDate);
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    },
    types() {
      if (!this.rfp) return;
      if (this.rfp.roomTypes) {
        const types = this.rfp
          .roomTypes.filter((type) => type.name.length > 0);
        return types;
      }
      const types = [];
      this.rfp.roomList.forEach((day) => {
        day.rooms.forEach((room) => {
          if (room.type.length > 0) types.push(room.type);
        });
      });
      return [...new Set(types)];
    },
    dates() {
      const days = [];
      this.rfp.roomList.forEach((day) => {
        days.push(day.date);
      });
      return [...new Set(days)];
    },
    rooms() {
      const roomsList = [];
      this.rfp.roomList.forEach((day) => {
        roomsList.push({
          day: day.date,
          ...day.rooms,
        });
      });
      return roomsList;
    },
    endDateFormated() {
      if (!this.rfp.endDate) return "";
      const date = this.rfp.endDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    startDateFormated() {
      if (!this.rfp.startDate) return "";
      const date = this.rfp.startDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    effectiveDateFormated() {
      if (!this.rfp.effective_date) return "";
      const date = this.rfp.effective_date;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    cutoffDateFormated() {
      if (!this.rfp.cutoffDate) return "";
      const date = this.rfp.cutoffDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    cutoffDateFormatted() {
      if (!this.rfp.cutoffDate) return "";
      return getMomentFromFirebaseDate(this.rfp.cutoffDate).format("D-M-Y");
    },
    isReadyToSign() {
      if (this.edit_mode) return false;
      if (this.isHotel) return this.hotelReadyToSign;
      if (this.isCrewfare) return this.crewfareReadyToSign;
      return false;
    },
    Date() {
      return moment().format("MM-DD-YYYY");
    },
    crewfareReadyToSign() {
      return (
        this.crewfare_signature?.length > 4 &&
        this.crewfare_date.length > 0 &&
        this.crewfare_name.length > 0 &&
        this.crewfare_title.length > 0
      );
    },
    hotelReadyToSign() {
      return (
        this.hotel_signature?.length > 4 &&
        this.hotel_date.length > 0 &&
        this.hotel_person_name.length > 0 &&
        this.hotel_title.length > 0
      );
    },
    hasBeenSigned() {
      if (this.isHotel) return this.signed.includes("hotel");
      if (this.isCrewfare) return this.signed.includes("crewfare");
      return false;
    },
    cutoffDays() {
      const date = getMomentFromFirebaseDate(this.rfp.startDate);
      const cutoff = getMomentFromFirebaseDate(this.rfp.cutoffDate);
      const diff = date.diff(cutoff);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    },
    isSigned() {
      console.log("Hotel sign status", this.isHotel, this.rfp.status.toLowerCase())
      if (this.isHotel) {
        return this.rfp.status.toLowerCase() === "signed by the hotel";
      } else {
        return this.rfp.status.toLowerCase() === "signed by crewfare";
      }
    },
    isFullySigned() {
      return this.rfp.signed?.length === 2;
    },
    isHotel() {
      return !this.isCrewfare;
    },
    isCrewfare() {
      return isCrewfareAccount(this.account)
    },
    hasEmptyField() {
      if (!this.rfp) return false;
      if (this.isHotel) {
        return (
          !this.rfp.hotel_person_name ||
          !this.rfp.hotel_email &&
          !this.rfp.hotel_title &&
          !this.rfp.hotel_taxes &&
          !this.rfp.hotel_check_in_age &&
          !this.rfp.hotel_check_in_time &&
          !this.rfp.hotel_check_out_time &&
          !this.rfp.hotel_local_occupancy_tax &&
          !this.rfp.hotel_state_occupancy_tax &&
          !this.rfp.hotel_city_tax &&
          !this.rfp.hotel_convention_tax &&
          !this.rfp.hotel_vat_tax &&
          !this.rfp.hotel_sat_tax &&
          !this.rfp.hotel_municipal_accomodation_tax &&
          !this.rfp.hotel_hst_tax &&
          !this.rfp.hotel_sustaintability_fee
        );
      }
      if (this.isCrewfare) {
        return !this.rfp.crewfare_members;
      }
      return false;
    },
    contractIsFilled() {
      return (
        this.rfp.hotel_person_name &&
        this.rfp.hotel_address &&
        this.rfp.hotel_email &&
        this.rfp.hotel_title &&
        this.rfp.hotel_taxes &&
        this.rfp.hotel_check_in_age &&
        this.rfp.hotel_check_in_time &&
        this.rfp.hotel_check_out_time &&
        this.rfp.hotel_local_occupancy_tax &&
        this.rfp.hotel_city_tax &&
        this.rfp.hotel_convention_tax &&
        this.rfp.hotel_vat_tax &&
        this.rfp.hotel_sat_tax &&
        this.rfp.hotel_municipal_accomodation_tax &&
        this.rfp.hotel_hst_tax &&
        this.rfp.hotel_sustaintability_fee &&
        this.rfp.crewfare_members
      );
    },
    isValid() {
      if (this.isHotel) {
        return (
          this.hotel_signature.length > 3 &&
          this.hotel_name.length > 0 &&
          this.hotel_person_name.length > 0 &&
          this.hotel_address.length > 0 &&
          this.hotel_email.length > 0 &&
          this.hotel_telephone.length > 0 &&
          this.hotel_title.length > 0 &&
          this.hotel_date.length > 0 &&
          this.rfp.hotel_check_in_age &&
          this.rfp.hotel_check_in_time &&
          this.rfp.hotel_check_out_time &&
          this.rfp.hotel_local_occupancy_tax &&
          this.rfp.hotel_city_tax &&
          this.rfp.hotel_convention_tax &&
          this.rfp.hotel_vat_tax &&
          this.rfp.hotel_sat_tax &&
          this.rfp.hotel_municipal_accomodation_tax &&
          this.rfp.hotel_hst_tax &&
          this.rfp.hotel_sustaintability_fee &&
          this.hotel_taxes.length > 0
        );
      }
      if (this.isCrewfare) {
        return (
          this.crewfare_signature.length > 3 &&
          this.crewfare_date.length > 0 &&
          this.crewfare_name.length > 0 &&
          this.crewfare_title.length > 0 &&
          this.crewfare_members.length > 0
        );
      }
      return false;
    },
    rooms() {
      const roomsList = [];
      this.rfp.roomList.forEach((day) => {
        roomsList.push({
          day: day.date,
          ...day.rooms,
        });
      });
      return roomsList;
    },
    endDateFormated() {
      if (!this.rfp.endDate) return "";
      const date = this.rfp.endDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    startDateFormated() {
      if (!this.rfp.startDate) return "";
      const date = this.rfp.startDate;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    effectiveDateFormated() {
      if (!this.rfp.effective_date) return "";
      const date = this.rfp.effective_date;
      return getMomentFromFirebaseDate(date).format("MM-DD-YYYY");
    },
    account() {
      return this.$store.state.account;
    },
  },
  async mounted() {
    this.crewfare_signature = jasonSignature;
    this.getData();
    document.title = `Launchpad - Crewfare - RFP - Contract`;

    window.addEventListener("resize", () => {
      if (this.$refs.signaturePadCrewfare)
        this.$refs.signaturePadCrewfare.fromDataURL(this.crewfare_signature);
      if (this.$refs.signaturePadHotel)
        this.$refs.signaturePadHotel.fromDataURL(this.hotel_signature);
    });
  },
  methods: {
    validateInputs(){
      if(this.hasBeenSigned){
        return;
      }
      //Remove any special characters from the input
      if(this.isHotel){
        this.hotel_person_name = this.hotel_person_name.replace(/[^a-zA-Z0-9 .]/g, "");
        this.hotel_title = this.hotel_title.replace(/[^a-zA-Z0-9 .]/g, "");
      }
      else{
        this.crewfare_title = this.crewfare_title.replace(/[^a-zA-Z0-9 .]/g, "");
        this.crewfare_name = this.crewfare_name.replace(/[^a-zA-Z0-9 .]/g, "");
      }
    },
    async restartSigningProcess() {
      if (!confirm("Are you sure you want to restart the signing process?"))
        return;
      const status = "Accepted by both sides";
      const rfpData = {
        ...this.rfp,
        status,
        updated_at: new Date(),
      };
      delete rfpData.signed;
      const data = await setStatusHistory({
        data: rfpData,
        content: "Signing process restart",
        status,
      });
      await setDoc(doc(firestore, "rfp_hotel", this.rfp.id), data);
      await this.updateParentRFPMeta();
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">${
                  this.rfp.name
                } signing process restarted!</div>
              </div>`,
      });
      router.push({
        name: "rfpsHotels",
        params: { id: this.rfp.rfp },
      });
    },
    getTotal() {
      const roomList = this.rfp.roomList;
      const total = roomList.map((day) =>
        day.rooms
          .map((room) => parseFloat(room.amount || 0))
          .reduce((accumulator, value) => accumulator + value, 0)
      );
      return total.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTotalByType(indexType) {
      const roomList = this.rfp.roomList;
      const total = roomList.map((day) =>
        day.rooms
          .filter((room, indexType) => indexType === indexType)
          .map((room) => parseFloat(room.amount || 0))
      );
      return total.reduce(
        (accumulator, value) => accumulator + parseFloat(value[0]),
        0
      );
    },
    getTotalByDay(rooms) {
      const amount = rooms.map((room) => parseFloat(room.amount || 0));
      return amount.reduce((accumulator, value) => accumulator + value, 0);
    },
    getTypeLoop() {
      let tdTypes = "";
      if (!this.types) return;
      this.types.forEach((date) => {
        tdTypes += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${date}
            </span>
            </span>
          </td>`;
      });
      return tdTypes;
    },
    getDateLoop() {
      let tdDates = "";
      if (!this.types) return;
      this.dates.forEach((date) => {
        tdDates += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${date}
            </span>
            </span>
          </td>`;
      });
      return tdDates;
    },
    getTotalByTypeLoop() {
      let tdTypes = "";
      if (!this.dates) return;
      this.dates.forEach((type, index) => {
        tdTypes += `<td style="padding: 4px 6px;text-align: center;"
          >
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${this.getTotalByType(index)}</span></span>
          </td>`;
      });
      return tdTypes;
    },
    getTotalByDateLoop() {
      let tdTypes = "";
      this.dates.forEach((date) => {
        let amount = 0;
        this.rfp
          .roomList.filter((day) => day.date === date)
          .forEach((days) => {
            amount = days.rooms
              .map((day) => day.amount)
              .reduce(
                (accumulator, value) => accumulator + parseFloat(value),
                0
              );
          });
        tdTypes += `<td style="padding: 4px 6px;text-align: center;"
          >
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${amount}</span></span>
          </td>`;
      });
      return tdTypes;
    },
    roomRateLoop() {
      let trRoomRate = "";
      this.types.forEach((type, typeIndex) => {
        const typeValue = type.name || type;
        trRoomRate += `<tr>
        <td
              style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
          ${typeValue}</span></span>
        </td>`;
        this.rfp.roomList.forEach((days) => {
          trRoomRate += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${
              days.rooms.find((room, index) => index === typeIndex)?.value || 0
            }</span></span>
          </td>`;
        });
        trRoomRate += `</tr>`;
      });
      return trRoomRate;
    },
    getRoomListLoop() {
      let trRoomList = "";
      this.types.forEach((type, typeIndex) => {
        const typeValue = type.name || type;
        trRoomList += `<tr>
        <td
              style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
          ${typeValue}</span></span>
        </td>`;
        let total = 0;
        this.rfp.roomList.forEach((days) => {
          const amount = parseFloat(
            days.rooms.find((room, index) => index === typeIndex)?.amount || 0
          );
          total += amount;
          trRoomList += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${amount}</span></span>
          </td>`;
        });
        trRoomList += `<td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold; text-align: center;"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${total}</span></span></td></tr>`;
      });
      return trRoomList;
    },
    getResortFee() {
      return this.rfp.resortFee === "Resort Fee"
        ? this.rfp.resortFeeAmount
        : this.rfp.resortFee;
    },
    getOtherFees() {
      return !this.rfp.otherFees
        ? "No"
        : this.rfp.otherFees === "Yes"
        ? this.rfp.otherFeesAmount
        : this.rfp.otherFees;
    },
    getOccupancyLoop() {
      let trOccupancyList = "";
      let types = this.rfp.roomTypes;
      if (!types) {
        types = [
          {
            name: "King",
            type: "Single",
            configuration: "1 King Bed",
            occupancy: "2",
            extra_value: "",
          },
          {
            name: "Double Queen",
            type: "Double",
            configuration: "Double Queen Beds",
            occupancy: "4",
            extra_value: "",
          },
        ];
      }
      types.forEach((days) => {
        trOccupancyList += `<tr>
          <td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.name}</span></span>
          </td>
          <td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.type || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.configuration || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.occupancy || "-"}</span></span>
          </td><td style="padding: 4px 6px;text-align: left;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
            ${days.extra_value || "-"}</span></span>
          </td>
          </tr>`;
      });
      return trOccupancyList;
    },
    getRoomListTable() {
      return `<table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "

            border="1"
            class="w-full"
          >
            <tr>
              <td  style="padding: 4px 6px;text-align: left;">Room Name<br/>
                (as seen on your website)</td>
              ${this.getDateLoop()}
              <td  style="padding: 4px 6px;text-align: center;">Total</td>
            </tr>
            ${this.getRoomListLoop()}
            <tr>
              <td  style="padding: 4px 6px;text-align: left;"></td>
              ${this.getTotalByDateLoop()}
              <td style="padding: 4px 6px;text-align: center;">
          <span>
          <span style="color: rgb(202, 138, 4); font-weigth: bold"
            ${this.protect_component} data-original-variable="{{room_type}}">
                ${this.getTotal()}
                </span></span>
              </td>
            </tr>
          </table>`;
    },
    getRoomTypeTable() {
      return `<table
            style="
              margin-top: 12px;
              width: 100%;
              border-color: #000;
              border-collapse: collapse;
            "
            border="1"
            class="w-full"
          >
            <tr>
              <td  style="padding: 4px 6px;text-align: left;">Room Name<br/>
                (as seen on your website)</td>
              <td  style="padding: 4px 6px;text-align: center;">Room Type</td>
              <td  style="padding: 4px 6px;text-align: center;">Room Configuration</td>
              <td  style="padding: 4px 6px;text-align: center;">Hotel Room<br/>Occupancy Limit</td>
              <td  style="padding: 4px 6px;text-align: center;">Additional Person<br/>Value</td>
            </tr>
            ${this.getOccupancyLoop()}
          </table>`;
    },
    getRoomRateTable() {
      return `<table
          style="
            margin-top: 12px;
            width: 100%;
            border-color: #000;
            border-collapse: collapse;
          "
          border="1"
          class="w-full"
          ${this.protect_component}
        >
          <tr>
            <td style="padding: 4px 6px;text-align: left;">Room Name <br/>(As seen in your website)</td>
            ${this.getDateLoop()}
          </tr>
          ${this.roomRateLoop()}
        </table>`;
    },
    getGroupHonoredRate() {
      return this.rfp.groupDateHonored === "No"
        ? `
        <div style="margin-top: 12px" ${this.protect_component}>
          The group rate is
          ${
            this.rfp.groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >$ ${this.rfp.groupDateHonoredPreRate}</span>
              </span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.groupDateHonoredPre &&
            this.rfp.groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>
          `
              : ""
          }
          ${
            this.rfp.groupDateHonoredPost
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >$ ${this.rfp.groupDateHonoredPost}</span></span>
            post
          </span>
          `
              : ""
          }
          on "Shoulder Nights" known as +/-
          ${
            this.rfp.groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.groupDateHonoredPreDays}</span></span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.groupDateHonoredPre &&
            this.rfp.groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>
          `
              : ""
          }
          ${
            this.rfp.groupDateHonoredPost
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.groupDateHonoredPostDays}</span></span>
            post
          </span>
          `
              : ""
          }
          the "Peak Nights".
        </div>
        `
        : "" + this.rfp.groupDateHonored === "Yes"
        ? `
        <div style="margin-top: 12px" ${this.protect_component}>
          The group rate is on "Shoulder Nights" known as +/-
          ${
            this.rfp.groupDateHonoredPre
              ? `
          <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.groupDateHonoredPreDays}</span></span>
            pre
          </span>
          `
              : ""
          }
          ${
            this.rfp.groupDateHonoredPre &&
            this.rfp.groupDateHonoredPost
              ? `
          <span>&nbsp;and&nbsp;</span>`
              : ""
          }
          ${
            this.rfp.groupDateHonoredPost
              ? `
            <span>
            <span>
            <span style="color: rgb(202, 138, 4); font-weigth: bold" class="!text-yellow-200 print:text-yellow-900"
              ${this.protect_component} data-original-variable="{{room_type}}"
              >${this.rfp.groupDateHonoredPostDays}</span></span>
              post
            </span>
          `
              : ""
          }
          the "Peak Nights".
        </div>
        `
        : "";
    },
    getChangeMappedByTime(changes){
      let newChangesMap = {}
      for(let key in changes){
        newChangesMap[`${key}-${changes[key].item.time}`] = changes[key]
      }
      return newChangesMap;
    },
    init_changes(editor, event){
      this.editor = editor;
      this.acceptedChanges= {};
      this.hoteldeclinedChanges = {};
      this.crewfareDeclinedChanges= {};
      this.originalChanges = {}
      this.hotelChanges = {}
      this.crewfareChanges = {}
      this.newChanges = {}
      const flite = event.flite
      const changes = this.getChangeMappedByTime(flite.getChanges().changes)
      const {hotelChanges, crewfareChanges} = this.groupChangeByCreator(changes)
      this.originalChanges = {...changes}
      this.hotelChanges = {...hotelChanges}
      this.crewfareChanges = {...crewfareChanges}
      this.changesInitiated = true
    },
    findUnavailableChanges(oldChanges, newChanges){
      newChanges = {...newChanges, ...this.acceptedChanges, ...this.hoteldeclinedChanges, ...this.crewfareDeclinedChanges}
      const changes = {}
      for(let key in oldChanges){
        if(!newChanges[key]){
          changes[key] = {...oldChanges[key]}
        }
      }
      return changes;
    },
    findNewChanges(oldChanges, newChanges){
      console.log({oldChanges, newChanges})
      const changes = {}
      for(let key in newChanges){
        if(!oldChanges[key]){
          changes[key] = {...newChanges[key]}
        }
      }
      return changes;
    },
    toggleHasChanges(editor, event) {
      if(!this.changesInitiated){
        return;
      }
      const flite = event.flite
      const changes = this.getChangeMappedByTime(flite.getChanges().changes)
      for(let key in changes){
        delete this.acceptedChanges[key];
        delete this.crewfareDeclinedChanges[key];
        delete this.hoteldeclinedChanges[key];
      }
      const newChanges = this.findNewChanges(this.originalChanges, changes)
      this.newChanges = {...newChanges};
      this.editor = editor;
      this.changesInitiated = true;
    },
    acceptChange(editor, event){
      const flite = event.flite
      const changes = this.getChangeMappedByTime(flite.getChanges().changes)
      const {hotelChanges, crewfareChanges} = this.groupChangeByCreator(changes)
      const hotelChangesAccepted= this.findUnavailableChanges(this.hotelChanges, hotelChanges)
      const crewfareChangesAccepted = this.findUnavailableChanges(this.crewfareChanges, crewfareChanges)
      const myChanges = this.isHotel ? hotelChangesAccepted: crewfareChangesAccepted
      const otherChanges = this.isHotel ? crewfareChangesAccepted: hotelChangesAccepted
      if(!Object.keys(otherChanges).length){
        alert("You can't accept your own change")
      }
      else if(Object.keys(myChanges).length){
        alert("You can't accept your own change")
        window.location = ""
        return;
      }
      this.acceptedChanges = {
        ...this.acceptedChanges,
        ...otherChanges
      }
      
    },
    rejectChange(editor, event){
      const flite = event.flite
      const changes = this.getChangeMappedByTime(flite.getChanges().changes)
      const {hotelChanges, crewfareChanges} = this.groupChangeByCreator(changes)
      const hotelChangesRejected= this.findUnavailableChanges(this.hotelChanges, hotelChanges)
      const crewfareChangesRejected = this.findUnavailableChanges(this.crewfareChanges, crewfareChanges)
      this.hoteldeclinedChanges = {
        ...this.hoteldeclinedChanges,
        ...hotelChangesRejected
      }
      this.crewfareDeclinedChanges = {
        ...this.crewfareDeclinedChanges,
        ...crewfareChangesRejected
      }
    },
    groupChangeByCreator(changes){
      let hotelChanges = {}
      let crewfareChanges = {}
      for(let changeId in changes){
        const change = changes[changeId]
        if(change.item.userid === "1"){
          hotelChanges[changeId] = change
        }
        else{
          crewfareChanges[changeId] = change
        }
      }
      return {hotelChanges, crewfareChanges}
    },
    async updateSignatureCrewfare() {
      if (this.$refs.signaturePadCrewfareHolder) {
        const el = this.$refs.signaturePadCrewfareHolder;
        const options = {
          type: "dataURL",
        };
        const image = await this.$html2canvas(el, options);
        this.signatureCrewfare = image;
        this.crewfare_signature = this.signatureCrewfare;
      }
    },
    async updateSignatureHotel() {
      if (this.$refs.signaturePadHotelHolder) {
        const el = this.$refs.signaturePadHotelHolder;
        const options = {
          type: "dataURL",
        };
        const image = await this.$html2canvas(el, options);
        this.signatureHotel = image;
        this.hotel_signature = this.signatureHotel;
      }
    },
    undo(type) {
      if (type === "hotel") this.$refs.signaturePadHotel.undoSignature();
      else this.$refs.signaturePadCrewfare.undoSignature();
    },
    save(type) {
      this.savingSign = true;
      if (type === "hotel") {
        const { isEmpty, data } = this.$refs.signaturePadHotel.saveSignature();
        if (isEmpty) {
          this.$store.commit("setToast", {
            content: `<div class='flex flex-col gap-2'>
            Please add your signature.
          </div>`,
          });
          this.savingSign = false;
          return;
        }
        if (!isEmpty) {
          this.hotel_signature = data;
        }
      } else if (type === "crewfare") {
        const { isEmpty, data } =
          this.$refs.signaturePadCrewfare.saveSignature();
        if (isEmpty) {
          this.$store.commit("setToast", {
            content: `<div class='flex flex-col gap-2'>
            Please add your signature.
          </div>`,
          });
          this.savingSign = false;
          return;
        }
        if (!isEmpty) {
          this.crewfare_signature = data;
        }
      }
      this.savingSign = false;
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
            Signature saved!
          </div>`,
      });
    },
    toggleContractMethod({ content }) {
      this.$emit("toggleContractMethod");
    },
    async saveComments(editor) {
      this.has_comments = true;
      this.editor = editor;
    },
    async editContract() {
      if(!(this.has_changes || this.has_comments)){
        alert("No changes found")
        return;
      }
      const contract_content = this.editor.getContent();
      if(!contract_content){
        alert("No content found")
        return;
      }
      this.updatingContract = true;
      const changeRes = await contractsApi.requestChange(this.rfp.id, {
        contract_content,
        changes: {
                    original: this.originalChanges,
                    hotelChanges: this.hotelChanges,
                    crewfareChanges: this.crewfareChanges,
                    newChanges: this.newChanges,
                    acceptedChanges: this.acceptedChanges,
                    myChanges: this.myChanges,
                    myDeclined: this.myDeclinedChanges,
                    othersDeclined: this.othersDeclinedChanges
                  }
      })
      if(changeRes.error){
        alert(changeRes.message || "Error requesting change")
        this.updatingContract = false;
        return;
      }
      console.log(changeRes)
      let notificationMessage = ''

      if(this.has_changes && this.has_comments){
        notificationMessage = `There are some new comments and changes suggested for the contract ${this.rfp.name} - ${this.rfp.hotel_name} by ${this.account.name}`
      }
      else if(this.has_changes){
        notificationMessage = `There are some new changes suggested for the contract ${this.rfp.name} - ${this.rfp.hotel_name} by ${this.account.name}`
      }
      else if(this.has_comments){
        notificationMessage = `There are some new comments suggested for the contract ${this.rfp.name} - ${this.rfp.hotel_name} by ${this.account.name}`
      }
      if(this.isHotel){
        sendNotificationAdmin({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          content: notificationMessage,
          to: { name: ViewsLocations.CONTRACT, params: { id: this.rfp.id, rfp: this.rfp.id } },
        });
      }
      else{
        sendNotificationHotels({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          content: notificationMessage,
          to: { name: "rfpContract", params: { rfp: this.rfp.id } },
          ids: [this.rfp.hotel_id],
        })
      }
      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
              Changes has been sent!
            </div>`,
      });
      this.updatingContract = false;
      this.has_comments = false;
      this.getData();
    },
    async acceptContractTerms() {
      const contract_content = this.editor.getContent();
      if(!contract_content){
        alert("No content found")
        return;
      }
      this.signingContract = true;
      let data = {
        ...this.rfp,
        contract_content,
        status: "Accepted Contract Updates",
        updated_at: new Date()
      };
      const acceptedRes = await contractsApi.acceptChanges(this.rfp.id, {
        contract_content,
        changes: {
          original: this.originalChanges,
          hotelChanges: this.hotelChanges,
          crewfareChanges: this.crewfareChanges,
          newChanges: this.newChanges,
          acceptedChanges: this.acceptedChanges,
          myChanges: this.myChanges,
          myDeclined: this.myDeclinedChanges,
          othersDeclined: this.othersDeclinedChanges
        }
      })
      if(acceptedRes.error){
        alert(acceptedRes.message || "Error accepting changes")
        this.signingContract = false;
        return;
      }
      let notificationMessage = `Changes for contract ${this.rfp.name} - ${this.rfp.hotel_name} has been accepted by ${this.account.name}`
      if(this.isHotel){
        sendNotificationAdmin({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          content: notificationMessage,
          to: { name: ViewsLocations.CONTRACT, params: { id: this.rfp.id, rfp: this.rfp.id } },
        });
      }
      else{
        sendNotificationHotels({
          type: NotificationTypes.RFP_STATUS_CHANGE,
          content: notificationMessage,
          to: { name: "rfpContract", params: { rfp: this.rfp.id } },
          ids: [this.rfp.hotel_id],
        })
      }

      this.$store.commit("setToast", {
        content: `<div class='flex flex-col gap-2'>
              Contract is accepted!
            </div>`,
      });
      this.signingContract = false;
      this.getData();
      this.regenerateContract();
    },
    async updateParentRFPMeta(){
      if(!this.parentRfp?.id){
        console.log("Parent RFP not available")
        return;
      }
      const parentRfpData = {
        ...this.parentRfp,
        updated_at: new Date()
      }
      console.log("updating parent rfp", parentRfpData)
      setDoc(doc(firestore, "rfps", parentRfpData.id), parentRfpData);
    },
    async getData() {
      this.contract_content = null;
      const contract = await contractsApi.get(this.$route.params.rfp);
      if(contract.error){
        alert(contract.message || "Error fetching contract")
        return;
      }
      this.rfp = contract.data
      console.log("RFP", this.rfp)
      this.templateContent = await this.getTemplateData(this.rfp.template);
      const parentRfp = await rfpsApi.get(this.rfp.rfp);
      if(parentRfp.error){
        alert(parentRfp.message || "Error fetching parent rfp")
        return;
      }
      this.parentRfp = parentRfp.data;
      const hotel = await hotelsApi.get(this.rfp.hotel_id)
      if(hotel.error){
        alert(hotel.message || "Error fetching hotel")
        return;
      }
      this.hotel = hotel.data;
      this.contractMethod = this.rfp.contractMethod;
      this.hotel_name = this.rfp.hotel_name || "";
      this.hotel_person_name = this.rfp.hotel_person_name || "";
      this.hotel_address = this.rfp.hotel_address || "";
      this.hotel_email = this.rfp.hotel_email || "";
      this.hotel_telephone = this.rfp.hotel_telephone || "";
      this.hotel_title = this.rfp.hotel_title || "";
      this.hotel_date =
        this.rfp.hotel_date || moment().format("dddd MMMM, DD, YYYY");
      this.hotel_taxes = this.rfp.hotel_taxes || "";
      this.hotel_other_fees = this.rfp.hotel_other_fees || "";
      this.crewfare_date =
        this.rfp.crewfare_date || moment().format("dddd MMMM, DD, YYYY");
      this.crewfare_members = this.rfp.crewfare_members || "";
      this.contract_content =
        this.rfp.contract_content || this.templateContent;
      this.edit_mode = this.rfp.edit_mode || false;
      this.contract_history = this.rfp.contract_history || null;
      this.hotel_signature = this.rfp.hotel_signature || null;
      this.signed = this.rfp.signed || [];
      if (this.signed.includes("hotel")) {
        this.crewfare_name =
          this.rfp.crewfare_name || this.crewfare_name;
        this.crewfare_title =
          this.rfp.crewfare_title || this.crewfare_title;
        this.crewfare_signature =
          this.rfp.crewfare_signature || this.crewfare_signature;
      } else {
        this.crewfare_name = this.rfp.crewfare_name || "";
        this.crewfare_title = this.rfp.crewfare_title || "";
        this.crewfare_signature = this.rfp.crewfare_signature || "";
      }
      if (this.edit_mode) this.setEditor();
      setTimeout(() => {
        if (this.crewfare_signature && this.$refs.signaturePadCrewfare)
          this.$refs.signaturePadCrewfare.fromDataURL(this.crewfare_signature);
        if (this.hotel_signature && this.$refs.signaturePadHotel)
          this.$refs.signaturePadHotel.fromDataURL(this.hotel_signature);
      }, 1000);
      const contractEditableVariables = {
        //hotel_address: this.rfp.hotel_address || ""
      }
      for (const [key, value] of Object.entries(contractEditableVariables)) {
        this.contract_content = this.contract_content.replaceAll(`[[${key}]]`, value);
      }
    },
    async getContract() {
      this.hide_signature = true;
      this.regenerating = true;

      const date = moment(new Date()).format("YYYY-MM-DD-hh-mm-ss");
      const file = await fetch(this.contractDownloadAsJSONLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => response.json());

      this.regenerating = false;
      this.hide_signature = false;
      return file;
    },
    async regenerateContract() {
      this.regenerating = true;

      await this.getData();
      let data = this.rfp;
      if(data?.signed?.length !== 2){
        this.regenerating = false;
        return;
      }
      const pdf_data = await this.getContract();
      const fileContent = pdf_data.content;
      const fileName = pdf_data.name
      const name = fileName.split(".")[0];
      const executeEmail = import.meta.env.VITE_EXECUTE_MAIL || "";

      sendEmail({
        to: executeEmail,
        template: "emails.new-default",
        attachment: [
          {
            content: fileContent,
            disposition: "inline",
            filename: fileName,
            name: name,
            type: "application/pdf",
          },
        ],
        subject: processVariables(contractExecutedInternalSubject, this.rfp),
        replyTo: this.rfp.replyTo,
        content: processVariables(contractExecutedInternalEmailContent, this.rfp),
      });
      const qContacts = query(
        collection(firestore, "contacts"),
        where("hotel_id", "==", this.rfp.hotel_id)
      );
      const contacts = await getDocs(qContacts);
      const contactList = contacts.docs;
      const executiveCCs = import.meta.env.VITE_EXECUTIVE_CC_MAILS.split(',') || [];
      let emailsTo = unique([...this.relatedEmails, ...executiveCCs]);
      contactList.forEach((contact) => {
        emailsTo.push(contact.data().email.toLowerCase().trim());
      });
      const link = `${import.meta.env.VITE_URL}/rfp/${this.rfp.id}/contract`;
      emailsTo = unique(emailsTo);
      sendEmail({
        to: emailsTo,
        banner: this.rfp.banner,
        template: "emails.new-default",
        subject: processVariables(contractExecutedSubject, this.rfp),
        replyTo: this.rfp.replyTo,
        content: processVariables(contractExecutedEmailContent, {...this.rfp, link}),
        attachment: [
          {
            content: fileContent,
            disposition: "inline",
            filename: fileName,
            name: name,
            type: "application/pdf",
          },
        ],
      });

      this.regenerating = false;
    },
    async generatePDF() {
      this.regenerating = true
      this.hide_signature = true
      this.generatingPDF = true;
      this.hide_signature = true;
      const file = await fetch(this.contractDownloadAsJSONLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }).then((response) => response.json());

      this.regenerating = false;
      this.hide_signature = false;
      this.generatingPDF = false;
      return file;
    },
    async accept() {
      if (!this.isReadyToSign) {
        this.signErrorModal = true;
        return;
      }
      this.error = false;
      this.signingContract = true;
      const signedRes = await contractsApi.sign(this.rfp.id, {
        signature: this.isHotel ? this.hotel_signature : this.crewfare_signature,
        name: this.isHotel ? this.hotel_person_name : this.crewfare_name,
        title: this.isHotel ? this.hotel_title : this.crewfare_title,
      });
      this.loadingSigned = false;
      if(signedRes.error){
        alert(signedRes.message || "Error signing contract")
        this.signingContract = false;
        return;
      }
      if (this.isHotel) {
        this.$store.commit("setToast", {
          content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The contract is signed! </div>
              </div>`,
        });
        router.push({
          name: "rfpContractSigned",
          params: { id: this.rfp.rfp },
        });
      } else {
        this.$store.commit("setToast", {
          content: `<div class='flex flex-col gap-2'>
                <div style="margin-top: 12px">The contract to ${
                  this.rfp.name
                } has been signed!</div>
              </div>`,
        });
        router.push({
          name: "rfpsHotels",
          params: { id: this.rfp.rfp },
        });
      }
      this.signingContract = false;
    },
    async saveUpdateContract({ data }) {
      const rfpData = {
        ...this.rfp,
        ...data,
        updated_at: new Date(),
      };
      this.editHotelFields = false;
      const rfpDataStatusHistory = await setStatusHistory({
        data: rfpData,
        content: "Contract Signed",
        status: rfpData.status,
      });
      await setDoc(
        doc(firestore, "rfp_hotel", this.rfp.id),
        rfpDataStatusHistory
      );
      await this.updateParentRFPMeta();
    },
    async getTemplateData(id) {
      id = id || "default";
      const templateData = id === 'default' ? DEFAULT_CREWFARE_CONTRACT : await templatesApi.get(id).then(res => res.data.content || DEFAULT_CREWFARE_CONTRACT);
      return templateData;
    },
  },
};
</script>