<template>
  <div v-if="rfp" class="bg-gray-800 w-full flex flex-col stage-height">
    <div class="p-4 flex justify-between bg-gray-900 items-center">
      <h1 class="text-xl text-white">Files</h1>
    </div>
    <div class="text-xl text-white gap-4 p-2 flex items-center justify-between">
      <span class="flex items-center gap-2">
        <button
          @click="$router.go(-1)"
          class="material-symbols-rounded hover:text-crewfareGreen"
        >
          chevron_left
        </button>
        {{ rfp.name || "Unknown RFP" }}
      </span>
    </div>
    <div v-if="rfps.length > 0">
      <table
        class="text-white w-full px-4 justify-between py-2 border border-gray-700 border-x-0 border-t-0"
      >
        <thead>
          <tr class="border-b border-gray-700">
            <th class="text-white text-left p-4">Hotel Name</th>
            <th class="text-white text-left px-4">Cutoff Date</th>
            <th class="text-white text-left px-4">Contract</th>
            <th class="text-white text-left px-4">Travel Coordinator</th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-700">
          <HotelListItem v-for="(rfp, index) in rfps" :key="index" :rfp="rfp" />
        </tbody>
      </table>
    </div>
    <div v-else>
      <p class="text-white my-4 text-center">
        There are no RFPs at the moment.
      </p>
    </div>
  </div>
</template>

<script>
import { rfpsApi } from "@/utils/apis/rfpsApi";
import HotelListItem from "@/components/files/HotelListItem.vue";

export default {
  components: {
    HotelListItem,
  },
  data() {
    return {
      rfp: null,
      rfps: [],
    };
  },
  async mounted() {
    this.getRfpsProposal();
  },
  methods: {
    async getRfpsProposal() {
      try {
        const response = await rfpsApi.listProposals(this.$route.params.id);
        const filteredProposals = response.data.filter(
          (e) => e.status.toLowerCase() === "signed by both sides"
        );

        this.rfps = filteredProposals.map(({ id, hotel_name, contractMethod, url, cutoffDate, startDate, endDate}) => ({
          id,
          hotel_name,
          contractMethod,
          url,
          cutoffDate,
          startDate,
          endDate,
        }));

        this.rfp = { name: `RFP ${this.$route.params.id}` };
      } catch (error) {
        console.error("Error fetching proposals:", error);
      }
    },
  },
};
</script>
