<template>
    <Modal v-if="prompt" :close="close">
        <div class="flex flex-col gap-y-4 py-4 max-w-[600px] items-center">
            <Text variant="h1" color="white" align="center">{{ prompt.title }}</Text>
            <Text variant="h2" color="white" align="center">{{ prompt.subtitle }}</Text>
            <textarea v-model="input" class="w-full h-[120px] text-black"></textarea>
            <Button :is-disabled="!input" :passed-function="handleSubmit" theme="dark">Submit</Button>
        </div>
    </Modal>
</template>
<script lang="ts">
import { Prompt } from '@/store';
import Modal from './Modal';
import Text from '../atoms/Text';
import Button from '../atoms/Button';

export default {
    components:{
        Modal,
        Text,
        Button
    },
    data(){
        return {
            input: ''
        }
    },
    computed:{
        prompt(): Prompt | null{
            return this.$store.state.prompts[0] || null;
        }
    },
    methods:{
        handleSubmit(){
            this.prompt?.callback(this.input);
            this.close();
        },
        close(){
            this.prompt?.callback('');
            this.$store.commit('removePrompt');
        }
    }
}
</script>